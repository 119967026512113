import React, { useContext, useEffect, useState } from "react";
import {Link} from "react-router-dom";
import DashboardHeader from "../../components/DashboardHead/DashboardHead";
import DashboardFoot from "../../components/DashboardFoot/DashboardFoot";
import DashboardIcons from "../../components/DashboardIcons/DashboardIcons";
import YourProgressGraph from "../../components/YourProgressGraph/YourProgressGraph";
import YourStatsList from "../../components/YourStatsList/YourStatsList";
import YourStatsListEdit from "../../components/YourStatsListEdit/YourStatsListEdit";
import NextSingleTab from "../../components/NextSingleTab/NextSingleTab";
import CompletedTraningSession from "../../components/CompletedTraningSession/CompletedTraningSession";
import InCompleteTraningSession from "../../components/InCompleteTraningSession/InCompleteTraningSession";
import NextTraningSession from "../../components/NextTraningSession/NextTraningSession";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

/*Import Images */

import "./Dashboard.scss";
import { UserContext } from "../../context/UserContext";
import customAxios from "../..";
import { ActivityContext, ActivityDispatchContext } from "../../context/ActivityContext";
import { Backdrop, CircularProgress } from "@mui/material";
import useDeviceDetect from "../../hooks/DeviceDetect";
import useDocumentTitle from "../../hooks/useDocumentTitle";

function Dashboard() {
  useDocumentTitle("Dashboard | TomFit AG - Switzerland");
  const userDetails : any = useContext(UserContext);
  const userActivityDetails : any = useContext(ActivityContext);
  const setUserActivityDetails = useContext(ActivityDispatchContext);
  const [openLoader, setOpenLoader] = useState(true);
  const [isStatsEditable, setIsStatsEditable] = useState(false);
  const [tabName, setTabName] = useState("progress");
  const {isMobile} = useDeviceDetect();

  useEffect(() => {
      let userActivityDetails: any = {};
      let username = localStorage.getItem("username");
      customAxios
        .get(`/users/api/activity?cognito_user_id=${username}`)
        .then((result) => {
          const {data} = result;
          userActivityDetails.progress = data;
      }).catch(() => {
        setOpenLoader(false);
        userActivityDetails.progress = {};
      });
      customAxios
        .get(`/users/api/user/stats/${username}`)
        .then((result) => {
          const {data} = result;
          userActivityDetails.stats = data;
      }).catch(() => {
          setOpenLoader(false);
        userActivityDetails.stats = {};
      });
      customAxios
        .get(`/users/api/workout?cognito_user_id=${username}`)
        .then((result) => {
          const {data} = result;
          userActivityDetails.training = data;
          setUserActivityDetails(userActivityDetails);
          setOpenLoader(false);
      }).catch(() => {
          setOpenLoader(false);
        userActivityDetails.training = {};
      });
  }, []);
  
  return (
    <>
      {openLoader ? (
        <Backdrop
          sx={{color: "#50C878", zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={openLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <DashboardHeader />
          <Box className="dashboard-main-area dashboard">
            <DashboardIcons />
            <Container fixed>
              <Box className="progress-area">
                <h6>WELCOME BACK {userDetails.first_name}!</h6>
                <Grid container spacing={4}>
                  {isMobile === false ? (
                    <>
                      <Grid item xs={12} md={9}>
                        {userActivityDetails && Object.hasOwn(userActivityDetails, 'progress') && <YourProgressGraph />}
                      </Grid>
                      <Grid item xs={12} md={3}>
                        {userActivityDetails && Object.hasOwn(userActivityDetails, 'stats') ? isStatsEditable === false ? (
                          <YourStatsList setIsStatsEditable={setIsStatsEditable} />
                        ) : (
                          <YourStatsListEdit setIsStatsEditable={setIsStatsEditable} />
                        ) : ''}
                      </Grid>
                    </>
                  ) : tabName === "progress" ? (
                    <Grid item xs={12} md={9}>
                      <Box className="progress-chart-head-btns">
                    <Button
                      variant="contained"
                      className={
                        tabName === "progress"
                          ? "grey-sm-btn active"
                          : "grey-sm-btn"
                      }
                      onClick={() => setTabName("progress")}
                    >
                      Your Progress
                    </Button>
                    <Button
                      variant="contained"
                      className="grey-sm-btn"
                      onClick={() => setTabName("stats")}
                    >
                      Your Stats
                    </Button>
                  </Box>
                      {userActivityDetails && Object.hasOwn(userActivityDetails, 'progress') && <YourProgressGraph />}
                    </Grid>
                  ) : (
                    tabName === "stats" && (
                      <Grid item xs={12} md={3}>
                        <Box className="progress-chart-head-btns">
                    <Button
                      variant="contained"
                      className="grey-sm-btn"
                      onClick={() => setTabName("progress")}
                    >
                      Your Progress
                    </Button>
                    <Button
                      variant="contained"
                      className={
                        tabName === "stats" ? "grey-sm-btn active" : "grey-sm-btn"
                      }
                      onClick={() => setTabName("stats")}
                    >
                      Your Stats
                    </Button>
                  </Box>
                        {userActivityDetails && Object.hasOwn(userActivityDetails, 'stats') ? isStatsEditable === false ? (
                          <YourStatsList setIsStatsEditable={setIsStatsEditable} />
                        ) : (
                          <YourStatsListEdit setIsStatsEditable={setIsStatsEditable} />
                        ) : ''}
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
              <Box className="training-area">
                <Typography variant="h2">Training</Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    {userActivityDetails && Object.hasOwn(userActivityDetails, 'training') && userActivityDetails.training?.incomplete?.name ? (
                      <>
                        <InCompleteTraningSession />
                        <NextTraningSession />
                      </>
                    ) : (
                      <NextSingleTab />
                    )}
                    <Box className="viewall">
                      <Link to="/your-training?session_type=next">VIEW ALL</Link>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {userActivityDetails && Object.hasOwn(userActivityDetails, 'training') &&
                      <CompletedTraningSession />
                    }
                    <Box className="viewall">
                      <Link to="/your-training?session_type=completed">VIEW ALL</Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
          <DashboardFoot />
        </>
      )}
    </>
  );
}

export default Dashboard;
