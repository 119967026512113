import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import 'tachyons';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  // document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const customAxios = axios.create({
  baseURL:  process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_STAGE,
  timeout: 20000,
  headers: { "Content-Type": "application/json" },
});

const requestHandler = (request: any) => {
  request.headers.Authorization = localStorage.getItem("idToken");
  //request.headers.Authorization = "Bearer " + localStorage.getItem("accessToken");
  request.headers["cognito-user-id"] = localStorage.getItem("username");
  return request;
};

const responseHandler = (response: any) => {
  return response;
};

// const errorHandler = (error: { response: { status: number; }; }) => {

const errorHandler = (error : any) => {
  if (
    (error.response.status === 401 || error.response.status === 403) &&
    !(window.location.pathname === "/login")
  ) {
    window.location.assign("/login");
  }
  else if(error.code == "ERR_NETWORK"){
    debugger;
    localStorage.clear();
    window.location.assign("/login");
  }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request: any) => requestHandler(request),
  (error: { response: { status: number; }; }) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response: any) => responseHandler(response),
  (error: { response: { status: number; }; }) => errorHandler(error)
);

export default customAxios;

