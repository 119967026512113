import React from "react";
import CompletedTraningTab from "../../components/CompletedTraningTab/CompletedTraningTab";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


/*Import Images */

import "./CompletedTraningSession.scss";


function CompletedTraningSession () {
  return (
    <>
    <Box className="completed-sessions-tabs completed">
    <Typography component="h6">COMPLETED SESSION</Typography>
    <CompletedTraningTab/>
    </Box>
    </>
  );
}

export default CompletedTraningSession;
