import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import DashboardHeader from "../DashboardHead/DashboardHead";
import DashboardFoot from "../DashboardFoot/DashboardFoot";
import DashboardIcons from "../DashboardIcons/DashboardIcons";
import CompletedTraningTab from "../CompletedTraningTab/CompletedTraningTab";
import InCompleteTraningSession from "../InCompleteTraningSession/InCompleteTraningSession";
import NextTraningSession from "../NextTraningSession/NextTraningSession";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./YourTraining.scss";
import UpComingTraningTab from "../UpComingTraningTab/UpComingTraningTab";
import { ActivityContext } from "../../context/ActivityContext";
import { Backdrop, CircularProgress } from "@mui/material";
import useDocumentTitle from "../../hooks/useDocumentTitle";

function YourTraining() {
  useDocumentTitle("Your Training | TomFit AG - Switzerland");
  const userActivityDetails : any = useContext(ActivityContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const element = document.getElementById(searchParams.get("session_type") || "");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <>
    {!userActivityDetails ? (
        <Backdrop
          sx={{color: "#50C878", zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={userActivityDetails ? false : true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
      <DashboardHeader />
      <Box className="dashboard-main-area tranings-area">
        <DashboardIcons />
        <Container fixed>
          <Box className="tranings-inner-area">
            <Box className="tranings-top">
              <Typography component="h1">Your Training</Typography>
              <Grid container spacing={3}>
              {userActivityDetails.training.incomplete && userActivityDetails.training.incomplete.name ?
              <>
                <Grid item xs={12} md={6}>
                  <InCompleteTraningSession />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NextTraningSession />
                </Grid>
                </>
                :
                <Grid item xs={12} md={12}>
                  <NextTraningSession />
                </Grid>
              }
              </Grid>
            </Box>
            <Box className="upcoming-traning-list">
              <div className="listing-inner">
                <Typography component="h6" className="bdr-heading" id="next">
                  Upcoming SESSIONS
                </Typography>
                <UpComingTraningTab />
              </div>
              {/* <Box className="loadmore">Load More</Box> */}
            </Box>
            <Box className="complete-tranings-list">
              <div className="listing-inner">
                <Typography component="h6" className="bdr-heading" id="completed">
                  COMPLETED SESSIONS
                </Typography>
                <CompletedTraningTab />
              </div>
              {/* <Box className="loadmore">Load More</Box> */}
            </Box>
          </Box>
        </Container>
      </Box>
      <DashboardFoot />
      </>
      )}
    </>
  );
}

export default YourTraining;
