import React, {useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {Alert, CircularProgress, Grid, Snackbar} from "@mui/material";
import Button from "@mui/material/Button";

/*Import Images */
import logo from "../../assets/images/tomfit.png";
import mobilelogo from "../../assets/images/logo-mobile.png";
import hangingmen from "../../assets/images/hanging-men.svg";
import img1 from "../../assets/images/Vector-6.png";
import img2 from "../../assets/images/blue-circle.png";
import img3 from "../../assets/images/cl-img.png";
import ibw from "../../assets/images/ibw-img.png";
import lcm from "../../assets/images/lcm-img.png";
import gcm from "../../assets/images/gc-img.png";
import cry from "../../assets/images/cry-img.png";
import bmbr from "../../assets/images/bmbr-img.png";
import trl from "../../assets/images/trl-img.png";
import hybl from "../../assets/images/hybl-img.png";

import {SubmitHandler, useForm, useFormState} from "react-hook-form";
import customAxios from "../..";
import {Auth} from "aws-amplify";

type Inputs = {
  otp: string;
  password: string;
};

function ConfirmPassword() {
  const navigate = useNavigate();
  const {state}: any = useLocation();
  const [open, setOpen] = useState(false);
  const [codeMismatch, setCodeMismatch] = useState(false);
  const [genericErrorOpen, setGenericErrorOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (values) => {
    setOpen(true);
    Auth.forgotPasswordSubmit(state?.email, values.otp, values.password)
      .then((value) => {
        setOpen(false);
        navigate("/login");
      })
      .catch((error) => {
        setOpen(false);
        if(error.code === "CodeMismatchException"){
          setCodeMismatch(true);
        }
        else{
          console.log(error);
          setGenericErrorOpen(true);
        }
      });
  };

  return (
    <>
      <Box className="main-area">
        <Box component="img" src={img1} className="sbr-img"></Box>
        <Box component="img" src={cry} className="cry-img"></Box>
        <Box className="mobile-icons">
          <Box component="img" src={mobilelogo} className="mobile-logo"></Box>
          <Box component="img" src={bmbr} className="bmbr-img"></Box>
          <Box component="img" src={trl} className="trl-img"></Box>
          <Box component="img" src={hybl} className="hybl-img"></Box>
        </Box>
        <Box className="main-inner-area">
          <Box component="img" src={img3} className="cl-img"></Box>
          <Box component="img" src={img2} className="bc-img"></Box>
          <Box className="main-inner-left">
            <Box component="img" className="logo" alt="" src={logo} />
            <Box component="img" src={hangingmen} className="hangingmen"></Box>
            <Box component="img" src={gcm} className="gc-img"></Box>
            <Box component="img" src={ibw} className="imw-img"></Box>
            <Box component="img" src={ibw} className="imw-img"></Box>
            <Box component="img" src={lcm} className="lcm-img"></Box>
          </Box>
          <Box className="main-inner-right main-inner-right-password">
            <Box className="form-area">
              <Typography variant="h1" component="h1">
                <Typography component="span">CREATE NEW PASSWORD</Typography>Enter your password reset code and new password!
              </Typography>
              <Box component="form" sx={{m: 1}}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={errors.otp ? true : false}
                      id="otp"
                      label="Password reset code"
                      type="password"
                      variant="outlined"
                      className="form-control"
                      {...register("otp", {required: true, minLength: 6})}
                    />
                    {errors?.otp?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.otp?.type === "minLength" && (
                      <span className="Alert">Cannot be less than 6 characters</span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={errors.password ? true : false}
                      id="Password"
                      label="New Password"
                      type="password"
                      variant="outlined"
                      className="form-control"
                      {...register("password", {required: true, minLength: 8})}
                    />
                    {errors?.password?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.password?.type === "minLength" && (
                      <span className="Alert">Cannot be less than 8 characters</span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                  <Box className="btn-loader">
                    {open ? <CircularProgress /> :
                      <Button
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        className="submit-btn"
                      >
                        Submit
                      </Button>
                    }  </Box> 
                  </Grid>
                </Grid>
              </Box>
              <Typography className="form-bt">
                Already have an account? <Link to="/login">Login</Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Snackbar open={codeMismatch} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setCodeMismatch(false)} autoHideDuration={10000}>
        <Alert
          onClose={() => setCodeMismatch(false)}
          severity="error"
          sx={{width: "100%"}}
        >
          Invalid verification code provided, please try again!
        </Alert>
      </Snackbar>

      <Snackbar open={genericErrorOpen} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setGenericErrorOpen(false)} autoHideDuration={10000}>
        <Alert
          onClose={() => setGenericErrorOpen(false)}
          severity="error"
          sx={{width: "100%"}}
        >
          There was an issue while processing your request. Please try again!
        </Alert>
      </Snackbar>
    </>
  );
}

export default ConfirmPassword;
