import React from "react";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";

/*Import Images */
import dIcon1 from "../../assets/images/d-icon1.svg";
import dIcon2 from "../../assets/images/d-icon2.svg";
import dIcon3 from "../../assets/images/d-icon3.svg";
import dIcon4 from "../../assets/images/d-icon4.svg";

import "./DashboardIcons.scss";

function DashboardIcons() {
  return (
    <>
      <Box className="dashboard-icons">
        <Box className="dicon dashboard-icon1" component="img" src={dIcon1}></Box>
        <Box className="dicon dashboard-icon2" component="img" src={dIcon2}></Box>
        <Box className="dicon dashboard-icon3" component="img" src={dIcon3}></Box>
        <Box className="dicon dashboard-icon4" component="img" src={dIcon4}></Box>
      </Box>
    </>
  );
}

export default DashboardIcons;
