import React, { useContext, useState } from "react";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

/*Import Images */

import "./YourStatsListEdit.scss";
import { ActivityContext, ActivityDispatchContext } from "../../context/ActivityContext";
import { Auth } from "aws-amplify";
import { SubmitHandler, useForm } from "react-hook-form";
import customAxios from "../..";
import { CircularProgress } from "@mui/material";
import {UserContext} from "../../context/UserContext";


type Inputs = {
  email: string;
  muscle_mass: number;
  weight: number;
};


function YourStatsListEdit(props: any) {
  const userDetails : any = useContext(UserContext);
  const userActivityDetails : any = useContext(ActivityContext);
  const setUserActivityDetails = useContext(ActivityDispatchContext);
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    const username = localStorage.getItem("username")!;
    values.email = userDetails.email;
    setOpen(true);
    await customAxios.put(`users/api/user/${username}`, values).then((result) => {
      const {data} = result;
      if (userActivityDetails.stats.musclemass.length === 0) {
        userActivityDetails.stats.musclemass.push({month: new Date().getMonth() + 1 , muscle_mass_average: data.muscle_mass}); // [0].muscle_mass_average = data.muscle_mass;
      }
      if (userActivityDetails.stats.weight.length === 0) {
        userActivityDetails.stats.weight.push({month: new Date().getMonth() + 1 , weight_average: data.weight}); //[0].weight_average = data.weight;
      }

      userActivityDetails.stats.musclemass[0].muscle_mass_average = data.muscle_mass;
      userActivityDetails.stats.weight[0].weight_average = data.weight;

      userActivityDetails.stats["musclemass-current"] = data["musclemass-current"];
      userActivityDetails.stats["musclemass-delta"] = data["musclemass-delta"];
      userActivityDetails.stats["weight-current"] = data["weight-current"];
      setUserActivityDetails(userActivityDetails);
      setOpen(false);
      props.setIsStatsEditable(false)
      })
  }
      
  return (
    <>
      <Box component="form" className="status-box edit">
        <Typography component="h2">Your Stats</Typography>

        <Box className="status-box-tab">
          <Typography component="h5">MUSCLE MASS</Typography>
          <Box className="icon-field">
            <TextField
              type="number"
              id="outlined-basic"
              variant="outlined"
              defaultValue={userActivityDetails.stats["musclemass-current"] ? parseFloat(userActivityDetails.stats["musclemass-current"]).toFixed(2) : ""}
              {...register("muscle_mass", {required: true})}
              />
              {errors?.muscle_mass?.type === "required" && (
                  <span className="Alert"> This field is required</span>
                )}
            <Box className="field-icon">kg</Box>
          </Box>
          {/* <Box className="icon-field wht">
            <TextField
              type="number"
              id="outlined-basic"
              variant="outlined"
              defaultValue="6.6"
            />
            <Box className="field-icon">%</Box>
          </Box> */}
        </Box>
        <Box className="status-box-tab">
          <Typography component="h5">WEIGHT</Typography>
          <Box className="icon-field">
            <TextField
              type="number"
              id="outlined-basic"
              variant="outlined"
              defaultValue={parseFloat(userActivityDetails.stats["weight-current"]).toFixed(2)}
              {...register("weight", {required: true})}
            />
            {errors?.weight?.type === "required" && (
                <span className="Alert"> This field is required</span>
              )}
            <Box className="field-icon">kg</Box>
          </Box>
        </Box>
        <Box className="stats-action-btns">
        <Box className="btn-loader">
          {open ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" className="update grn-btn" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          )}
        </Box>
          <Button
            variant="text"
            className="simple-link"
            onClick={() => props.setIsStatsEditable(false)}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default YourStatsListEdit;