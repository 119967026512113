import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import { useCookies } from 'react-cookie';

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

/*Import Images */
import iIcon from '../../assets/images/i-icon.svg';

import "./YourProgressGraph.scss";
import { ActivityContext } from "../../context/ActivityContext";
import moment from "moment";


function YourProgressGraph () {
  const [cookies, setCookie] = useCookies(['escoreInfo']);
  const userActivityDetails : any = useContext(ActivityContext);
  const [datesData, setDatesData] = useState([]);
  const [escoreData, setEscoreData] = useState([]);

  ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


  useEffect(() => {
    let localDatesData : any = [];
    let localEscoreData : any = [];

    for(let i = userActivityDetails?.progress?.progress?.length - 1; i >=0; i--){
      localDatesData.push(moment(userActivityDetails.progress.progress[i].created).format("MMM D").toUpperCase());
    }

    for(let i = userActivityDetails?.progress?.progress?.length - 1; i >=0; i--){
      localEscoreData.push(userActivityDetails.progress.progress[i].escore);
    }

    setDatesData(localDatesData);
    setEscoreData(localEscoreData);
  }, [])

  return (
    <>
      
        <>
          {/* <Box className="progress-chart-head-btns">
            <Button variant="contained" className={props.tabName === "progress" ? "grey-sm-btn active" : "grey-sm-btn"} onClick={() => props.setTabName("progress")}>
              Your Progress
            </Button>
            <Button variant="contained" className={props.tabName === "stats" ? "grey-sm-btn active" : "grey-sm-btn"} onClick={() => props.setTabName("stats")}>
              Your Stats
            </Button>
          </Box> */}
          <Box className="progress-chart">
            <Box className="progress-chart-head">
              <Typography component="h2">Your Progress</Typography>
            </Box>
            <Box className="progress-chart-body">
              {/* {datesData.length > 0 && escoreData.length > 0 && ( */}
              <Line
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      ticks: {
                        color: "#6C7F81",
                        font: {
                          size: 12,
                          weight: "bold",
                          family: "Outfit",
                        },
                      },
                    },
                    x: {
                      ticks: {
                        color: "#6C7F81",
                        font: {
                          size: 12,
                          weight: "bold",
                          family: "Outfit",
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: datesData,
                  datasets: [
                    {
                      data: escoreData,
                      borderColor: "rgb(53, 162, 235)",
                      backgroundColor: "rgba(53, 162, 235, 0.5)",
                    },
                  ],
                }}
              />
            </Box>
            {!cookies.escoreInfo ? (
              <Box className="progress-chart-foot">
                <Box className="progress-chart-foot-left top">
                  E-SCORE
                  <span className="lef-per">
                    {userActivityDetails.progress["escore-current"]}%
                  </span>
                </Box>
                <Box className="progress-chart-foot-center">
                  <Box className="progress-chart-foot-center-left">
                    <Box component="img" src={iIcon}></Box>
                    <span>
                      The Tomly E-Score measures your overall average effort over time
                    </span>
                  </Box>
                  <Button
                    variant="outlined"
                    className="grn-bdr-btn"
                    onClick={() => setCookie("escoreInfo", true)}
                  >
                    OK, GOT IT
                  </Button>
                </Box>
                {userActivityDetails.progress["escore-delta"] >= 0 ? (
                  <Box className="progress-chart-foot-right bottom">
                    {" "}
                    <span>+{userActivityDetails.progress["escore-delta"]}%</span>
                  </Box>
                ) : (
                  <Box className="progress-chart-foot-right bottom minus-delta">
                    {" "}
                    <span>{userActivityDetails.progress["escore-delta"]}%</span>
                  </Box>
                )}
              </Box>
            ) : (
                <Box className="progress-chart-foot after-ok">
                  <Box className="progress-chart-foot-left top">
                    <div className="e-source">
                      E-SCORE <Box component="img" src={iIcon}></Box>
                      <Box className="progress-chart-foot-center progress-chart-foot-info-toltip">
                        The Tomly E-Score measures your overall average effort over time
                      </Box>
                    </div>
                    <span className="lef-per">
                      {userActivityDetails.progress["escore-current"]}%
                    </span>
                  </Box>
                  {userActivityDetails.progress["escore-delta"] >= 0 ? (
                    <Box className="progress-chart-foot-right bottom">
                      {" "}
                      <span>+{userActivityDetails.progress["escore-delta"]}%</span>
                    </Box>
                  ) : (
                    <Box className="progress-chart-foot-right bottom minus-delta">
                      {" "}
                      <span>{userActivityDetails.progress["escore-delta"]}%</span>
                    </Box>
                  )}
                </Box>
            )}
          </Box>
        </>
    </>
  );
}

export default YourProgressGraph;
