import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';


/*Import Images */
import scanImg from '../../assets/images/scaner-icon.svg';

import "./DashboardFoot.scss";


function DashboardFoot () {
  const navigate = useNavigate();
  return (
    <>
     <Box className="dashboard-foot">
      <Box className="d-copyright">
      © {new Date().getFullYear()} TomFit AG
      </Box>
      <Box className="df-links">
        {/*<Link to="#">Link</Link>*/}
      </Box>
      <Box className="mobile-scanner">
        <Button variant="contained" className="grn-btn" onClick={() => navigate("/qrscanner")}>SCAN MACHINE <Box component="img" src={scanImg}></Box></Button>
      </Box> 
     </Box>
    </>
  );
}

export default DashboardFoot;
