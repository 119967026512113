import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

/*Import Images */

import "./InCompleteTraningSession.scss";
import { ActivityContext } from "../../context/ActivityContext";
import moment from "moment";


function InCompleteTraningSession () {
  const userActivityDetails : any = useContext(ActivityContext);
  
  return (
    <>
      <Box className="completed-sessions-tabs incomplete">
        <Typography component="h6">INCOMPLETE SESSION</Typography>
        <Link
          to="/training-detail"
          state={{session: userActivityDetails?.training.incomplete.activity}}
        >
          <Box className="completed-session-tab incomplete">
            <Box className="completed-session-tab-date">
              {moment(new Date(userActivityDetails?.training.incomplete.date)).format(
                "D"
              )}
              <span>
                {moment(new Date(userActivityDetails?.training.incomplete.date)).format(
                  "MMM"
                )}
              </span>
            </Box>
            <Box className="completed-session-tab-info">
              <Typography component="h5">
                {userActivityDetails?.training.incomplete.name}
                <span>{userActivityDetails?.training.incomplete.type.toUpperCase()}</span>
              </Typography>
            </Box>
            <Box className="incomplete-per">
              {userActivityDetails?.training.incomplete.progress}%
            </Box>
            <Box className="session-tab-action">
              <Button variant="contained" className="grn-btn">
                Complete
              </Button>
            </Box>
          </Box>
        </Link>
      </Box>
    </>
  );
}

export default InCompleteTraningSession;
