import React, { createContext, useState } from "react";


// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const ActivityContext = createContext(undefined);
const ActivityDispatchContext = createContext<any>(undefined);


function ActivityContextProvider({ children } : any) {
  const [userActivityDetails, setUserActivityDetails] = useState();

  return (
    <ActivityContext.Provider value={userActivityDetails}>
      <ActivityDispatchContext.Provider value={setUserActivityDetails}>
        {children}
      </ActivityDispatchContext.Provider>
    </ActivityContext.Provider>
  );
}

export { ActivityContextProvider, ActivityContext, ActivityDispatchContext };