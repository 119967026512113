import React, { useContext, useEffect, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {Alert, Backdrop, CircularProgress, Grid, Snackbar} from "@mui/material";
import Button from "@mui/material/Button";

/*Import Images */
import logo from "../../assets/images/tomfit.png";
import mobilelogo from "../../assets/images/logo-mobile.png";
import hangingmen from "../../assets/images/hanging-men.svg";
import img1 from "../../assets/images/Vector-6.png";
import img2 from "../../assets/images/blue-circle.png";
import img3 from "../../assets/images/cl-img.png";
import ibw from "../../assets/images/ibw-img.png";
import lcm from "../../assets/images/lcm-img.png";
import gcm from "../../assets/images/gc-img.png";
import cry from "../../assets/images/cry-img.png";
import bmbr from "../../assets/images/bmbr-img.png";
import trl from "../../assets/images/trl-img.png";
import hybl from "../../assets/images/hybl-img.png";

import "./Login.scss";
import {SubmitHandler, useForm, useFormState} from "react-hook-form";
import customAxios from "../..";
import {Auth} from "aws-amplify";
import { UserDispatchContext } from "../../context/UserContext";
import useDocumentTitle from "../../hooks/useDocumentTitle";

type Inputs = {
  email: string;
  password: string;
};

function Login() {
  useDocumentTitle("Login | TomFit AG - Switzerland");
  const navigate = useNavigate();
  const { state } : any = useLocation();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Inputs>();
  const [userAlreadyExistsOpen, setUserAlreadyExistsOpen] = useState(state?.userAlreadyExists ? true : false);
  const [userInvalidOpen, setUserInvalidOpen] = useState(false);
  const [genericErrorOpen, setGenericErrorOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const setUserDetails = useContext(UserDispatchContext);

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    setUserAlreadyExistsOpen(false);
    setOpen(true);
    try {
      const response = await Auth.signIn({
        username: values.email,
        password: values.password,
      });

       //Storing user tokens for user session and api calls
       localStorage.setItem("idToken", response.signInUserSession.idToken.jwtToken);
       localStorage.setItem(
         "accessToken",
         response.signInUserSession.accessToken.jwtToken
       );
       localStorage.setItem(
         "refreshToken",
         response.signInUserSession.refreshToken.token
       );
       localStorage.setItem("username", response.username);

      await customAxios.get(`/users/api/user/${response.username}`).then((result) => {
        const {data} = result;
        setUserDetails(data);
        setOpen(false);
        navigate("/dashboard");
      });
    } catch (error: any) {
      if (error.code === "NotAuthorizedException") {
        setOpen(false);
        setUserInvalidOpen(true);
      }
      else{
        setOpen(false);
        setGenericErrorOpen(true);
      }
    }
  };

  return (
    <>
      <Box className="main-area">
        <Box component="img" src={img1} className="sbr-img"></Box>
        <Box component="img" src={cry} className="cry-img"></Box>
        <Box className="mobile-icons">
          <Box component="img" src={mobilelogo} className="mobile-logo"></Box>
          <Box component="img" src={bmbr} className="bmbr-img"></Box>
          <Box component="img" src={trl} className="trl-img"></Box>
          <Box component="img" src={hybl} className="hybl-img"></Box>
        </Box>
        <Box className="main-inner-area">
          <Box component="img" src={img3} className="cl-img"></Box>
          <Box component="img" src={img2} className="bc-img"></Box>
          <Box className="main-inner-left">
            <Box component="img" className="logo" alt="" src={logo} />
            <Box component="img" src={hangingmen} className="hangingmen"></Box>
            <Box component="img" src={gcm} className="gc-img"></Box>
            <Box component="img" src={ibw} className="imw-img"></Box>
            <Box component="img" src={ibw} className="imw-img"></Box>
            <Box component="img" src={lcm} className="lcm-img"></Box>
          </Box>
          <Box className="main-inner-right main-inner-right-login">
            <Box className="form-area form-area-login">
              <Typography variant="h1" component="h1">
                <Typography component="span">LOGIN</Typography>Start your transformation
                today!
              </Typography>
              <Box component="form" sx={{m: 1}}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={errors.email ? true : false}
                      id="Email"
                      label="Email address"
                      defaultValue={state?.email ? state.email : ""}
                      type="email"
                      variant="outlined"
                      className="form-control"
                      {...register("email", {required: true, pattern: /\S+@\S+\.\S+/})}
                    />
                    {errors?.email?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.email?.type === "pattern" && (
                      <span className="Alert"> Invalid email</span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={errors.password ? true : false}
                      id="Password"
                      label="Password"
                      autoFocus={state?.userAlreadyExists ? true: false}
                      type="password"
                      variant="outlined"
                      className="form-control"
                      {...register("password", {required: true, minLength: 8})}
                    />
                    {errors?.password?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.password?.type === "minLength" && (
                      <span className="Alert">Cannot be less than 8 characters</span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                  <Box className="btn-loader">
                    <Link to="/confirmation">
                    {open ? <CircularProgress /> :
                      <Button
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        className="submit-btn w-100"
                      >
                        Submit
                      </Button>
                    }         
                    </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Typography className="form-forgot">
               <Link to="/forgot">Forgot Password ?</Link>
              </Typography>
              <Typography className="form-bt">
                Don't have an account? <Link to="/register">Register</Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Snackbar open={userAlreadyExistsOpen} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setUserAlreadyExistsOpen(false)} autoHideDuration={10000}>
        <Alert
          onClose={() => setUserAlreadyExistsOpen(false)}
          severity="error"
          sx={{width: "100%"}}
        >
          User already exists, Log in to continue!
        </Alert>
      </Snackbar>

      <Snackbar open={userInvalidOpen} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setUserInvalidOpen(false)} autoHideDuration={10000}>
        <Alert
          onClose={() => setUserInvalidOpen(false)}
          severity="error"
          sx={{width: "100%"}}
        >
          Invalid Email address or Password!
        </Alert>
      </Snackbar>

      <Snackbar open={genericErrorOpen} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setGenericErrorOpen(false)} autoHideDuration={10000}>
        <Alert
          onClose={() => setGenericErrorOpen(false)}
          severity="error"
          sx={{width: "100%"}}
        >
          There was an issue while processing your request. Please try again!
        </Alert>
      </Snackbar>

      {/* <Backdrop
        sx={{ color: '#50C878', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      > */}
        
      {/* </Backdrop> */}
    </>
  );
}

export default Login;
