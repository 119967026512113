import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/*Import Images */

import "./NoDataAvailable.scss";

function NoDataAvailable(props: any) {
  return (
    <>
      <Box className="no-sessions-tabs">
        <Typography component="h6">No Data Available</Typography>
      </Box>
    </>
  );
}

export default NoDataAvailable;
