import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import stepIcon from '../../../assets/images/height-icon.svg';
import './RegisterHeight.scss';
import {SubmitHandler, useForm} from 'react-hook-form';
import { UserContext, UserDispatchContext } from '../../../context/UserContext';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

type Inputs = {
  height: number;
};

type Props = {
  data: any;
  setData: Function;
  setStep: Function;
  step: number;
};

function RegisterHeight(props: Props) {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    props.setData({...props.data,
      height: values.height,
    });
    props.setStep(props.step + 1);
  };

  return (
    <>
      <Typography variant="h1" component="h1">
        What is your <br></br>height?
      </Typography>
      <Box component="form">
        <Grid container>
          <Grid item>
            <Box className="cong-tab-icon stepIcon">
              <Box component="img" src={stepIcon}></Box>
            </Box>
            <Box className="greenTextBox">
              This allows the machine to adjust ergonomically for each exercise for a more
              comfortable training experience
            </Box>

            <Box className="step-form">
              <form>
                <Box className="icon-field">
                  <div className="form-input-wrapper">
                    <TextField
                      error={errors.height ? true : false}
                      type="number"
                      id="height"
                      value={props.data.height}
                      label="Enter height"
                      variant="outlined"
                      className="form-control"
                      {...register("height", {
                        onChange: (e) => {props.setData({...props.data, height: e.target.value})},
                        required: true,
                        pattern: /^[0-9]*$/
                      })}
                    />
                    <Box className="field-icon">cm</Box>
                    {errors?.height?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.height?.type === "pattern" && (
                      <span className="Alert">Only numbers allowed without decimals and comma</span>
                    )}
                  </div>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    className="grn-btn"
                    type="submit"
                  >
                    CONTINUE
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default RegisterHeight;
