import React from "react";
import Amplify from "aws-amplify";
import CustomRouter from "./Router";
import { UserContextProvider } from "./context/UserContext";
import { ActivityContextProvider } from "./context/ActivityContext";


Amplify.configure({
  "aws_project_region": "eu-central-1",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": process.env["REACT_APP_ENV"] === 'production' ? "eu-central-1_ACkity336" : "eu-central-1_kszCgqkiK",
  "aws_user_pools_web_client_id": process.env["REACT_APP_ENV"] === 'production' ? "5kl5cpctiiiiijg1d245jiq6k7" : "73i8j7c7fkpc3aj4tfd8322bb0",
  "oauth": {
    "domain": process.env["REACT_APP_ENV"] === 'production' ? "tomfit-users-prod.auth.eu-central-1.amazoncognito.com" : "tomfit-users.auth.eu-central-1.amazoncognito.com",
  },
  "aws_cognito_username_attributes": [
    "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 6,
    "passwordPolicyCharacters": [
      "REQUIRES_LOWERCASE"
    ]
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ]
});

function App() {
  
  return (
    <>
      <UserContextProvider>
        <ActivityContextProvider>
        <CustomRouter />
        </ActivityContextProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
