import React, {useEffect, useState} from "react";
import DashboardHeader from "../../components/DashboardHead/DashboardHead";
import DashboardFoot from "../../components/DashboardFoot/DashboardFoot";
import DashboardIcons from "../../components/DashboardIcons/DashboardIcons";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import moment from "moment";

/*Import Images */
import backArrow from "../../assets/images/back-left-arrow.svg";

import "./Tomly.scss";
import customAxios from "../..";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TomlyAction from "../TomlyActions/TomlyAction";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";

function Tomly() {
  useDocumentTitle("Tomly's | TomFit AG - Switzerland");
  const [createCode, setcreateCode] = useState(false);
  const [removeCode, setRemoveCode] = useState(false);
  const [changeCode, setChangeCode] = useState(false);
  const [openLoader, setOpenLoader] = useState(true);
  const [tomlys, setTomlys] = useState<any>([]);

  const [otp, setOtp] = useState("");

  useEffect(() => {
    let username = localStorage.getItem("username");
    try {
      (async () => {
        await customAxios
          .get(`/users/api/machine?cognito_user_id=${username}`)
          .then((result) => {
            const {data} = result;
            setTomlys(data);
            setOpenLoader(false);
          });
      })();
    } catch (error: any) {
      setOpenLoader(false);
    }
  }, []);

  const handleChange = (otp: any) => {
    setOtp(otp);
  };

  return (
    <>
      {openLoader ? (
        <Backdrop
          sx={{color: "#50C878", zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={openLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <DashboardHeader />
          <Box className="dashboard-main-area tomly-detail-area">
            <DashboardIcons />
            <Container fixed>
              <Box className="tomly-inner-area">
                <Typography variant="h1" className="simple-main-heading">
                  Tomly’s
                </Typography>
                <Box className="tomly-tabs-area">
                  <Typography variant="h6" className="bdr-heading">
                    RECENTLY USED TOMLY’s
                  </Typography>
                  <Box className="tomly-tabs">
                    {tomlys.recent.length !== 0 ? (
                      tomlys.recent.map((recentMachine: any, index: any) => (
                        <Box className="tomly-tab" key={index}>
                          <Box className="tomly-tab-info">
                            <Typography variant="h5">
                              {recentMachine.machine.first_name}{" "}
                              <span>
                                {moment(new Date(recentMachine["used-date"])).format(
                                  "DD.MM.YYYY"
                                )}
                              </span>
                            </Typography>
                          </Box>
                          <TomlyAction machineData={recentMachine} />
                        </Box>
                      ))
                    ) : (
                      <NoDataAvailable />
                    )}
                  </Box>
                </Box>
                <Box className="tomly-tabs-area">
                  <Typography variant="h6" className="bdr-heading">
                    YOUR TOMLY’S
                  </Typography>
                  <Box className="tomly-tabs">
                    {tomlys.your.length !== 0 ? (
                      tomlys.your.map((yourMachine: any, index: any) => (
                        <Box className="tomly-tab" key={index}>
                          <Box className="tomly-tab-info">
                            <Typography variant="h5">
                              {yourMachine.machine.first_name}
                              <span>
                                {moment(new Date(yourMachine["used-date"])).format(
                                  "DD.MM.YYYY"
                                )}
                              </span>
                            </Typography>
                          </Box>
                          <TomlyAction machineData={yourMachine} />
                        </Box>
                      ))
                    ) : (
                      <NoDataAvailable />
                    )}
                  </Box>
                </Box>
                <Box className="tomly-tabs-area">
                  <Typography variant="h6" className="bdr-heading">
                    OTHER TOMLY’S
                  </Typography>
                  <Box className="tomly-tabs other">
                    {tomlys.other.length !== 0 ? (
                      tomlys.other.map((otherMachine: any, index: any) => (
                        <Box className="tomly-tab" key={index}>
                          <Box className="tomly-tab-info">
                            <Typography variant="h5">
                              {otherMachine.machine.first_name}
                              <span>
                                {moment(new Date(otherMachine["used-date"])).format(
                                  "DD.MM.YYYY"
                                )}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <NoDataAvailable />
                    )}
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
          <DashboardFoot />
        </>
      )}
    </>
  );
}

export default Tomly;
