import React, { useContext } from "react";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/*Import Images */

import "./NextSingleTab.scss";
import { ActivityContext } from "../../context/ActivityContext";
import moment from "moment";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";

function NextSingleTab() {
  const userActivityDetails : any = useContext(ActivityContext);
  return (
    <>
      <Box className="next-session-tabs">
        <Typography component="h6">Next Session</Typography>
        {/* <Link to="/traning-detail" state={{session: userActivityDetails?.training.next[0].activity}}> */}
        {userActivityDetails?.training.next.length !== 0 ? 
          <Box className="next-session-tab" style={{cursor: "default"}}>
            {userActivityDetails?.training.next && userActivityDetails?.training.next[0].date ?
            <Box className="next-session-tab-data">
              {moment(new Date(userActivityDetails?.training.next[0].date)).format("D")}
              <span>
                {moment(new Date(userActivityDetails?.training.next[0].date)).format(
                  "MMM"
                )}
              </span>
            </Box>
            :
            <Box className="next-session-tab-data">
              -
            </Box>  
            }
            <Typography component="h5">
              {userActivityDetails?.training.next ? userActivityDetails?.training.next[0].name : ''}{" "}
              <span>{userActivityDetails?.training.next ? userActivityDetails?.training.next[0].type.toUpperCase() : ''}</span>
            </Typography>
          </Box>
          :
          <NoDataAvailable />
          }
        {/* </Link> */}
      </Box>
    </>
  );
}

export default NextSingleTab;
