import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

/*Import Images */

import "./NextTraningSession.scss";
import { ActivityContext } from "../../context/ActivityContext";
import moment from "moment";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";


function NextTraningSession () {
  const userActivityDetails : any = useContext(ActivityContext);
  return (
    <>
    {userActivityDetails?.training.next.length !== 0 ? 
      <Box className="completed-sessions-tabs next">
        <Typography component="h6">Next SESSION</Typography>
        {/* <Link to="/training-detail" state={{session: userActivityDetails?.training.next[0].activity}}> */}
        <Box className="completed-session-tab incomplete"  style={{cursor: "default"}}>
          {userActivityDetails?.training.next[0].date ? 
          <Box className="completed-session-tab-date">
          {moment(new Date(userActivityDetails?.training.next[0].date)).format("D")}
            <span>
            {moment(new Date(userActivityDetails?.training.next[0].date)).format("MMM")}
            </span>
          </Box>
          :
          <Box className="completed-session-tab-date">
          -
          </Box>
          }
          <Box className="completed-session-tab-info">
            <Typography component="h5">
              {userActivityDetails?.training.next[0].name}
              <span>{userActivityDetails?.training.next[0].type.toUpperCase()}</span>
            </Typography>
          </Box>
          <Box className="session-tab-action">
            <Button variant="outlined" className="grn-bdr-btn">
              Start
            </Button>
          </Box>
        </Box>
        {/* </Link> */}
      </Box>
      :
      <NoDataAvailable />
      }
    </>
  );
}

export default NextTraningSession;
