import React, {useContext, useEffect, useState} from "react";
import DashboardHeader from "../../components/DashboardHead/DashboardHead";
import DashboardFoot from "../../components/DashboardFoot/DashboardFoot";
import DashboardIcons from "../../components/DashboardIcons/DashboardIcons";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import "./Profile.scss";
import {UserContext, UserDispatchContext} from "../../context/UserContext";
import {Alert, InputLabel, MenuItem, Select, Snackbar} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import customAxios from "../..";
import ChangePassword from "../ChangePassword/ChangePassword";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import moment from "moment";

type FName = {
  first_name: string;
};

type LName = {
  last_name: string;
};

type Gender = {
  gender: string;
};

type Height = {
  height: string;
};

type Birthday = {
  day: number;
  month: number;
  year: number;
};

function Profile() {
  useDocumentTitle("Profile | TomFit AG - Switzerland");
  const userDetails: any = useContext(UserContext);
  const setUserDetails = useContext(UserDispatchContext);

  const [editFirstName, setEditFirstName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [editGender, setEditGender] = useState(false);
  const [editHeight, setEditHeight] = useState(false);
  const [editBirthday, setEditBirthday] = useState(false);
  const [editEmail, setEditEmail] = useState(false);

  const [changePasswordToggle, setchangePasswordToggle] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);

  // const [openLoaderFName, setOpenLoaderFName] = useState(false);
  // const [openLoaderLName, setOpenLoaderLName] = useState(false);
  // const [openLoaderGender, setOpenLoaderGender] = useState(false);

  const {
    register: registerFName,
    handleSubmit: handleSubmitFName,
    formState: {errors: errorsFName},
    resetField: resetFName,
  } = useForm<FName>();

  const {
    register: registerLName,
    handleSubmit: handleSubmitLName,
    formState: {errors: errorsLName},
    resetField: resetLName,
  } = useForm<LName>();

  const {
    register: registerGender,
    handleSubmit: handleSubmitGender,
    formState: {errors: errorsGender},
    reset: resetGender,
  } = useForm<Gender>();

  const {
    register: registerHeight,
    handleSubmit: handleSubmitHeight,
    formState: {errors: errorsHeight},
    reset: resetHeight,
  } = useForm<Height>();

  const {
    register: registerBirthday,
    handleSubmit: handleSubmitBirthday,
    formState: {errors: errorsBirthday},
    reset: resetBirthday,
  } = useForm<Birthday>();

  const onSubmitFName : SubmitHandler<FName> = async (values) => {
      // setOpenLoaderFName(true);
      userDetails.first_name = values.first_name;
      userDetails.birthday = new Date(userDetails.birthday);
      await customAxios
        .put(`users/api/user/${userDetails.cognito_user_id}`, userDetails)
        .then((result) => {
          const {data} = result;
          setUserDetails(data);
          // setOpenLoaderFName(false);
          setEditFirstName(!editFirstName);
          setEditSuccessOpen(true);
        });
  };

  const onSubmitLName : SubmitHandler<LName> = async (values) => {
    // setOpenLoaderLName(true);
    userDetails.last_name = values.last_name;
    userDetails.birthday = new Date(userDetails.birthday);
    await customAxios
      .put(`users/api/user/${userDetails.cognito_user_id}`, userDetails)
      .then((result) => {
        const {data} = result;
        setUserDetails(data);
        // setOpenLoaderLName(false);
        setEditLastName(!editLastName);
        setEditSuccessOpen(true);
      });
};

const onSubmitGender : SubmitHandler<Gender> = async (values) => {
  // setOpenLoaderGender(true);
  userDetails.gender = values.gender;
  userDetails.birthday = new Date(userDetails.birthday);
  await customAxios
    .put(`users/api/user/${userDetails.cognito_user_id}`, userDetails)
    .then((result) => {
      const {data} = result;
      setUserDetails(data);
      // setOpenLoaderGender(false);
      setEditGender(!editGender);
      setEditSuccessOpen(true);
    });
};

const onSubmitHeight : SubmitHandler<Height> = async (values) => {
  userDetails.height = values.height;
  userDetails.birthday = new Date(userDetails.birthday);
  await customAxios
    .put(`users/api/user/${userDetails.cognito_user_id}`, userDetails)
    .then((result) => {
      const {data} = result;
      setUserDetails(data);
      setEditHeight(!editHeight);
      setEditSuccessOpen(true);
    });
};

const onSubmitBirthday : SubmitHandler<Birthday> = async (values) => {
  userDetails.birthday = new Date(values.year, values.month - 1, values.day);
  await customAxios
    .put(`users/api/user/${userDetails.cognito_user_id}`, userDetails)
    .then((result) => {
      const {data} = result;
      setUserDetails(data);
      setEditBirthday(!editBirthday);
      setEditSuccessOpen(true);
    });
};

  useEffect(() => {
    userDetails.birthday = new Date(userDetails.birthday);
  }, [userDetails.birthday]);

  return (
    <>
      <DashboardHeader />
      <Box className="dashboard-main-area profile-area">
        <DashboardIcons />
        <Container fixed>
          <Box className="profile-inner-area">
            <Typography variant="h1">Profile</Typography>
            <Box className="profile-detail-area">
              <Box className="profile-tabs-area">
                <Typography variant="h4">Personal Details</Typography>
                <Box className="profile-detail-form">
                  <Box
                    className={
                      editFirstName ? "form-field-area" : "form-field-area noteditable"
                    }
                  >
                    <Box className="form-field">
                      <TextField
                        disabled={!editFirstName}
                        id="firstName"
                        label="FIRST NAME"
                        variant="outlined"
                        defaultValue={userDetails.first_name}
                        {...registerFName("first_name", {
                          required: true,
                          maxLength: 20,
                          minLength: 3,
                          pattern: /^[A-Za-züöäéèàÜÖÄÉÈÀ]+$/i,
                        })}
                      />
                      {errorsFName?.first_name?.type === "required" && (
                        <span className="Alert">This field is required</span>
                      )}
                      {errorsFName?.first_name?.type === "maxLength" && (
                        <span className="Alert">
                          First name cannot exceed 20 characters
                        </span>
                      )}
                      {errorsFName?.first_name?.type === "minLength" && (
                        <span className="Alert">Cannot be less than 3 characters</span>
                      )}
                      {errorsFName?.first_name?.type === "pattern" && (
                        <span className="Alert">Alphabetical characters only</span>
                      )}
                    </Box>
                    <Box className="field-action">
                      {editFirstName ? (
                        <>
                          {/* {openLoaderFName ? <CircularProgress sx={{ color: '#50C878'}}/> :  */}
                          <Button
                            variant="outlined"
                            className="grn-bdr-btn"
                            onClick={handleSubmitFName(onSubmitFName)}
                          >
                            SAVE
                          </Button>
                          {/* } */}
                          <Button
                            variant="text"
                            className="simple-link"
                            onClick={() => {
                              setEditFirstName(!editFirstName);
                              resetFName("first_name", {
                                defaultValue: userDetails.first_name,
                              });
                            }}
                          >
                            CANCEL
                          </Button>{" "}
                        </>
                      ) : (
                        <Button
                          variant="text"
                          className="simple-link"
                          onClick={() => {
                            setEditFirstName(!editFirstName);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box
                    className={
                      editLastName ? "form-field-area" : "form-field-area noteditable"
                    }
                  >
                    <Box className="form-field">
                      <TextField
                        disabled={!editLastName}
                        id="lastName"
                        label="LAST NAME"
                        variant="outlined"
                        defaultValue={userDetails.last_name}
                        {...registerLName("last_name", {pattern: /^[A-Za-züöäéèàÜÖÄÉÈÀ]+$/i})}
                      />
                      {errorsLName?.last_name?.type === "pattern" && (
                        <span className="Alert">Alphabetical characters only</span>
                      )}
                    </Box>
                    <Box className="field-action">
                      {editLastName ? (
                        <>
                          {/* {openLoaderLName ? <CircularProgress /> :  */}
                          <Button
                            variant="outlined"
                            className="grn-bdr-btn"
                            onClick={handleSubmitLName(onSubmitLName)}
                          >
                            SAVE
                          </Button>
                          {/* } */}
                          <Button
                            variant="text"
                            className="simple-link"
                            onClick={() => {
                              setEditLastName(!editLastName);
                              resetLName("last_name", {
                                defaultValue: userDetails.last_name,
                              });
                            }}
                          >
                            CANCEL
                          </Button>{" "}
                        </>
                      ) : (
                        <Button
                          variant="text"
                          className="simple-link"
                          onClick={() => {
                            setEditLastName(!editLastName);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box
                    className={
                      editGender ? "form-field-area" : "form-field-area noteditable"
                    }
                  >
                    <Box className="form-field select-field">
                      {/* <TextField disabled={!editGender} id="outlined-basic" label="GENDER" variant="outlined" defaultValue={userDetails.gender} /> */}
                      <InputLabel id="Gender">Gender</InputLabel>
                      <Select
                        disabled={!editGender}
                        labelId="gender-label"
                        id="gender"
                        label="Gender"
                        variant="outlined"
                        className="form-control"
                        defaultValue={userDetails.gender}
                        {...registerGender("gender", {required: true})}
                      >
                        <MenuItem value={"M"}>Male</MenuItem>
                        <MenuItem value={"F"}>Female</MenuItem>
                      </Select>
                      {errorsGender?.gender?.type === "required" && (
                        <span className="Alert"> This field is required</span>
                      )}
                    </Box>
                    <Box className="field-action">
                      {editGender ? (
                        <>
                          {/* {openLoaderGender ? <CircularProgress /> :  */}
                          <Button
                            variant="outlined"
                            className="grn-bdr-btn"
                            onClick={handleSubmitGender(onSubmitGender)}
                          >
                            SAVE
                          </Button>
                          {/* } */}
                          <Button
                            variant="text"
                            className="simple-link"
                            onClick={() => {
                              setEditGender(!editGender);
                              resetGender({gender: userDetails.gender});
                              //resetGender("gender", {defaultValue: userDetails.gender});
                            }}
                          >
                            CANCEL
                          </Button>{" "}
                        </>
                      ) : (
                        <Button
                          variant="text"
                          className="simple-link"
                          onClick={() => {
                            setEditGender(!editGender);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box
                    className={
                      editHeight ? "form-field-area" : "form-field-area noteditable"
                    }
                  >
                    <Box className="form-field">
                      <TextField
                        disabled={!editHeight}
                        id="height"
                        label="HEIGHT"
                        variant="outlined"
                        defaultValue={Math.round(userDetails.height)}
                        {...registerHeight("height", {pattern: /^[0-9]*$/})}
                      />
                      {errorsHeight?.height?.type === "pattern" && (
                        <span className="Alert">Numbers only</span>
                      )}
                    </Box>
                    <Box className="field-action">
                      {editHeight ? (
                        <>
                          {/* {openLoaderLName ? <CircularProgress /> :  */}
                          <Button
                            variant="outlined"
                            className="grn-bdr-btn"
                            onClick={handleSubmitHeight(onSubmitHeight)}
                          >
                            SAVE
                          </Button>
                          {/* } */}
                          <Button
                            variant="text"
                            className="simple-link"
                            onClick={() => {
                              setEditHeight(!editHeight);
                              resetHeight({
                                height: userDetails.height,
                              });
                            }}
                          >
                            CANCEL
                          </Button>{" "}
                        </>
                      ) : (
                        <Button
                          variant="text"
                          className="simple-link"
                          onClick={() => {
                            setEditHeight(!editHeight);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  </Box>
                  {!editBirthday &&
                    <Box className="form-field-area birthday-container noteditable">
                      <Box className="form-field">
                        <TextField
                          label="BIRTHDAY"
                          id="birthday"
                          disabled={!editBirthday}
                          defaultValue={
                            (new Date(userDetails.birthday).getDate() + 1) + '.' +
                            (new Date(userDetails.birthday).getMonth() + 1) + '.' +
                            new Date(userDetails.birthday).getFullYear()
                          }
                          variant="outlined"
                          className="form-control"
                        />
                      </Box>
                      <Box className="field-action">
                        <Button
                          variant="text"
                          className="simple-link"
                          onClick={() => {
                            setEditBirthday(!editBirthday);
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                    </Box>
                  }
                  {editBirthday &&
                    <Box className="form-field-area birthday-container">
                      <Box className="form-field birthday-input-box">
                        <TextField
                          disabled={!editBirthday}
                          defaultValue={new Date(userDetails.birthday).getDate() + 1}
                          error={errorsBirthday.day ? true : false}
                          type="number"
                          id="day"
                          label="DD"
                          inputProps={{ min: 1, max: 31 }}
                          variant="outlined"
                          className="form-control"
                          {...registerBirthday("day", {
                            required: true,
                            minLength: 1,
                            maxLength: 2,
                            min: 1,
                            max: 31,
                            pattern: /([1-9]|[12]\d|3[01])$/
                          })}
                        />
                        <TextField
                          disabled={!editBirthday}
                          defaultValue={new Date(userDetails.birthday).getMonth() + 1}
                          error={errorsBirthday.month ? true : false}
                          type="number"
                          id="month"
                          label="MM"
                          inputProps={{ min: 1, max: 12 }}
                          variant="outlined"
                          className="form-control"
                          {...registerBirthday("month", {
                            required: true,
                            minLength: 1,
                            maxLength: 2,
                            min: 1,
                            max: 12,
                            pattern: /^(0?[1-9]|1[012])$/
                          })}
                        />
                        <TextField
                          disabled={!editBirthday}
                          defaultValue={new Date(userDetails.birthday).getFullYear()}
                          error={errorsBirthday.year ? true : false}
                          type="number"
                          id="year"
                          label="YYYY"
                          variant="outlined"
                          className="form-control birthday-year"
                          {...registerBirthday("year", {
                            required: true,
                            minLength: 4,
                            maxLength: 4,
                            min: 1900,
                            max: new Date().getFullYear(),
                            pattern: /^(19|20)\d{2}$/
                          })}
                        />
                        {errorsHeight?.height?.type === "pattern" && (
                          <span className="Alert">Numbers only</span>
                        )}
                      </Box>
                      <Box className="field-action">
                          <Button
                            variant="outlined"
                            className="grn-bdr-btn"
                            onClick={handleSubmitBirthday(onSubmitBirthday)}
                          >
                            SAVE
                          </Button>
                          <Button
                            variant="text"
                            className="simple-link"
                            onClick={() => {
                              setEditBirthday(!editBirthday);
                              resetBirthday({
                                day: userDetails.birthday,
                              });
                            }}
                          >
                            CANCEL
                          </Button>{" "}
                      </Box>
                    </Box>
                  }
                  <Box
                    className={
                      editEmail ? "form-field-area" : "form-field-area noteditable"
                    }
                  >
                    <Box className="form-field email-field">
                      <TextField
                        disabled={!editEmail}
                        id="email"
                        label="EMAIL"
                        variant="outlined"
                        defaultValue={userDetails.email}
                        // {...register("email", {required: true, pattern: /\S+@\S+\.\S+/})}
                      />
                      {/* {errors?.email?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.email?.type === "pattern" && (
                      <span className="Alert"> Invalid email</span>
                    )} */}
                    </Box>
                    {/* <Box className="field-action">
                      {editEmail ? (
                        <>
                          <Button variant="outlined" className="grn-bdr-btn">
                            SAVE
                          </Button>
                          <Button
                            variant="text"
                            className="simple-link"
                            onClick={() => {
                              setEditEmail(!editEmail);
                            }}
                          >
                            CANCEL
                          </Button>{" "}
                        </>
                      ) : (
                        <Button
                          variant="text"
                          className="simple-link"
                          onClick={() => {
                            setEditEmail(!editEmail);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Box> */}
                  </Box>
                </Box>
              </Box>
              <ChangePassword />
            </Box>
          </Box>
        </Container>
      </Box>
      <DashboardFoot />

      <Snackbar
        open={editSuccessOpen}
        anchorOrigin={{vertical: "top", horizontal: "center"}}
        onClose={() => setEditSuccessOpen(false)}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setEditSuccessOpen(false)}
          severity="success"
          sx={{width: "100%"}}
        >
          User details updated succesfully!
        </Alert>
      </Snackbar>
    </>
  );
}

export default Profile;
