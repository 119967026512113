import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import OtpInput from "react-otp-input";
import CircularProgress from "@mui/material/CircularProgress";
import customAxios from "../..";

function TomlyAction(props: any) {
  const [createCode, setCreateCode] = useState(false);
  const [removeCode, setRemoveCode] = useState(false);
  const [changeCode, setChangeCode] = useState(false);
  const [oldNotMatch, setOldNotMatch] = useState(false);
  const [open, setOpen] = useState(false);
  const [codeExist, setCodeExist] = useState(false);
  const [otp, setOtp] = useState("");
  const [oldotp, setOldotp] = useState("");
  const [newotp, setNewotp] = useState("");

  const handleCodeActions = async (action: string) => {
    setOpen(true);

    if(action === "Change" && props.machineData.code != oldotp){
        setOldNotMatch(true);
        setOpen(false);
        return false;
    }

    let values = {
      cognito_user_id: localStorage.getItem("username"),
      cognito_machine_id: props.machineData.machine.cognito_machine_id,
      code:
        action === "Create"
          ? otp
          : action === "Change"
          ? newotp
          : action === "Remove" && "",
    };
    try {
      await customAxios.post(`users/api/code`, values).then((result) => {
        const {data} = result;
        props.machineData.code = data.code;
        action === "Create"
          ? setCreateCode(!createCode)
          : action === "Change"
          ? setChangeCode(!changeCode)
          : action === "Remove" && setRemoveCode(!removeCode);
        setOtp("");
        setOldotp("");
        setNewotp("");
        setOldNotMatch(false);
        setOpen(false);
        setCodeExist(false);
      });
    } catch (error: any) {
      setOpen(false);
      if (error.response.data.error === "code_exist") {
        setCodeExist(true);
      }
    }
  };

  return (
    <>
      <Box className="right-action-div">
        {props.machineData.code === "" ? (
          createCode ? (
            <>
              <Box className="tomly-tab-code-actions">
                <Typography variant="h6">CREATE NEW CODE</Typography>
                <div className="otp-code-field">
                  <OtpInput
                    value={otp}
                    onChange={(otp: any) => setOtp(otp)}
                    numInputs={4}
                  />
                </div>
                <Box className="tomly-tab-actions">
                  <Box className="btn-loader">
                    {open ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="outlined"
                        className="grn-bdr-btn"
                        onClick={() => handleCodeActions("Create")}
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      variant="text"
                      className="simple-link"
                      onClick={() => {
                        setOtp("");
                        setCreateCode(!createCode);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box className="tomly-tab-actions">
                <Button
                  variant="outlined"
                  className="grn-bdr-btn"
                  onClick={() => {
                    setCreateCode(!createCode);
                  }}
                >
                  Create Code
                </Button>
              </Box>
            </>
          )
        ) : (
          !removeCode &&
          !changeCode && (
            <>
              <Box className="tomly-tab-actions">
                <Button
                  variant="outlined"
                  className="grn-bdr-btn"
                  onClick={() => {
                    setChangeCode(!changeCode);
                  }}
                >
                  CHANGE ACCESS CODE
                </Button>
                <Button
                  variant="text"
                  className="simple-link"
                  onClick={() => {
                    setRemoveCode(!removeCode);
                  }}
                >
                  REMOVE CODE
                </Button>
              </Box>
            </>
          )
        )}
        {changeCode && (
          <>
            <Box className="tomly-tab-code-actions">
              <Typography variant="h6">CHANGE ACCESS CODE</Typography>
              <div className="otp-code-field">
                <Box className="otp-heading">Old code</Box>
                <OtpInput
                  value={oldotp}
                  onChange={(otp: any) => setOldotp(otp)}
                  numInputs={4}
                />
                {oldNotMatch && (
                  <span className="Alert"> Old Code did not match. Try Again!</span>
                )}
              </div>
              <div className="otp-code-field">
                <Box className="otp-heading">New code</Box>
                <OtpInput
                  value={newotp}
                  onChange={(otp: any) => setNewotp(otp)}
                  numInputs={4}
                />
                {codeExist && (
                  <span className="Alert">Code already exist.</span>
                )}
              </div>
              <Box className="tomly-tab-actions">
                <Box className="btn-loader">
                  {open ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="outlined"
                      className="grn-bdr-btn"
                      onClick={() => handleCodeActions("Change")}
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    variant="text"
                    className="simple-link"
                    onClick={() => {
                      setChangeCode(!changeCode);
                      setOldotp("");
                      setNewotp("");
                      setOldNotMatch(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        )}
        {removeCode && (
          <>
            <Box className="tomly-tab-code-actions">
              <Typography variant="h6">REMOVE CODE</Typography>
              <p>Are you sure you want to remove the code for this machine?</p>
              <Box className="tomly-tab-actions">
                <Box className="btn-loader">
                  {open ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="outlined"
                      className="grn-bdr-btn"
                      onClick={() => handleCodeActions("Remove")}
                    >
                      CONFIRM
                    </Button>
                  )}
                  <Button
                    variant="text"
                    className="simple-link"
                    onClick={() => {
                      setRemoveCode(!removeCode);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default TomlyAction;
