import React, { useContext, useState } from "react";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/*Import Images */
import starImg from "../../assets/images/star.svg";
import "./UpComingTraningTab.scss";
import { ActivityContext } from "../../context/ActivityContext";
import moment from "moment";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";

const trainingsPerColumn = 4;

function UpComingTraningTab() {
  const userActivityDetails : any = useContext(ActivityContext);
  const [next, setNext] = useState(trainingsPerColumn);

  const handLoadleMore = () => {
    if (next + trainingsPerColumn >= userActivityDetails.training.next.length) {
      setNext(userActivityDetails.training.next.length);
    } else {
      setNext(next + trainingsPerColumn);
    }
  };
  
  return (
    <>
      {userActivityDetails?.training.next.length !== 0 ?
      userActivityDetails?.training.next
        .slice(1, next)
        .map((item: any, index: any) => (
          // <Link key={index} to="/training-detail" state={{session: item.id}}>
            <Box key={index} className="completed-session-tab" style={{cursor: "default"}}>
              {item.date ? 
              <Box className="completed-session-tab-date">
                {moment(new Date(item.date)).format("D")}
                <span>{moment(new Date(item.date)).format("MMM")}</span>
              </Box>
              :
              <Box className="completed-session-tab-date">
                -
                </Box>
              }
              <Box className="completed-session-tab-info">
                <Typography component="h5">
                  {item.name} <span>{item.type.toUpperCase()}</span>
                </Typography>
              </Box>
            </Box>
          // </Link>
        ))
      :
      <NoDataAvailable/>
      }
      {next < userActivityDetails.training.next.length && (
        <Box className="loadmore" onClick={() => handLoadleMore()}>
          Load More
        </Box>
      )}
    </>
  );
}

export default UpComingTraningTab;
