import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Alert,
  CircularProgress, InputAdornment,
  Snackbar,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import stepIcon from "../../assets/images/weight-icon.svg";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

type Inputs = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

function ChangePassword() {
  const [changePasswordToggle, setchangePasswordToggle] = useState(false);
  const [passwordSuccessOpen, setPasswordSuccessOpen] = useState(false);
  const [userInvalidOpen, setUserInvalidOpen] = useState(false);
  const [passwordLimitOpen, setPasswordLimitOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const handleClickShowConfirmNewPassword = () => setShowConfirmNewPassword(!showConfirmNewPassword);
  const handleMouseDownConfirmNewPassword = () => setShowConfirmNewPassword(!showConfirmNewPassword);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: {errors},
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    setOpen(true);
    Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, values.oldPassword, values.newPassword);
    })
    .then((data) => {
        reset();
        setOpen(false);
        setchangePasswordToggle(false);
        setPasswordSuccessOpen(true);
      })
      .catch((error) => {
        setOpen(false);
        if (error.code === "LimitExceededException") {
          reset();
          setchangePasswordToggle(false);
          setPasswordLimitOpen(true);
        }
        else if (error.code === "NotAuthorizedException") {
          reset();
          setUserInvalidOpen(true);
        }
      });

    // let user = await Auth.currentUserInfo();
    // Auth.changePassword(user, values.oldPassword, values.newPassword)
    //   .then((value) => {
    //     setOpen(false);
    //   })
    //   .catch((error) => {
    //     setOpen(false);
    //     console.log(error);
    //   });
  };

  return (
    <>
      <Box className="profile-tabs-area">
        <Typography variant="h4">Security</Typography>
        {changePasswordToggle ? (
          <Box className="change-pass active">
            <Typography variant="h5">CHANGE PASSWORD</Typography>
            <Box className="change-pass-form">
              <Box component="form" sx={{m: 1}}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="oldPassword"
                      label="Old password"
                      type={showOldPassword ? "text" : "password"}
                      variant="outlined"
                      className="form-control"
                      {...register("oldPassword", {required: true, minLength: 8})}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              onMouseDown={handleMouseDownOldPassword}
                            >
                              {showOldPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {errors?.oldPassword?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.oldPassword?.type === "minLength" && (
                      <span className="Alert">Cannot be less than 8 characters</span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={errors.newPassword ? true : false}
                      id="newPassword"
                      label="New password"
                      type={showNewPassword ? "text" : "password"}
                      variant="outlined"
                      className="form-control"
                      {...register("newPassword", {required: true, minLength: 8})}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownNewPassword}
                            >
                              {showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <Box className="field-icon" component="img" src={stepIcon}></Box>
                    {errors?.newPassword?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.newPassword?.type === "minLength" && (
                      <span className="Alert">Cannot be less than 8 characters</span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={errors.confirmNewPassword ? true : false}
                      id="confirmNewPassword"
                      label="Confirm new password"
                      type={showConfirmNewPassword ? "text" : "password"}
                      variant="outlined"
                      className="form-control"
                      {...register("confirmNewPassword", {
                        required: true,
                        minLength: 8,
                        validate: {
                          passwordMatch: (value) => value === getValues().newPassword,
                        },
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmNewPassword}
                              onMouseDown={handleMouseDownConfirmNewPassword}
                            >
                              {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {errors?.confirmNewPassword?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.confirmNewPassword?.type === "minLength" && (
                      <span className="Alert">Cannot be less than 8 characters</span>
                    )}
                    {errors?.confirmNewPassword?.type === "passwordMatch" && (
                      <span className="Alert">Passwords must match!</span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="pass-action-btns">
                      <Box className="btn-loader">
                        {open ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            onClick={handleSubmit(onSubmit)}
                            variant="outlined"
                            className="grn-bdr-btn"
                          >
                            Save
                          </Button>
                        )}
                        <Button
                          variant="text"
                          className="simple-link"
                          onClick={() => setchangePasswordToggle(false)}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="change-pass" onClick={() => setchangePasswordToggle(true)}>
            <Typography variant="h5">CHANGE PASSWORD</Typography>
          </Box>
        )}

        <Snackbar
          open={passwordSuccessOpen}
          anchorOrigin={{vertical: "bottom", horizontal: "center"}}
          onClose={() => setPasswordSuccessOpen(false)}
          autoHideDuration={6000}
        >
          <Alert
            onClose={() => setPasswordSuccessOpen(false)}
            severity="success"
            sx={{width: "100%"}}
          >
            Password changed succesfully!
          </Alert>
        </Snackbar>

        <Snackbar
          open={passwordLimitOpen}
          anchorOrigin={{vertical: "bottom", horizontal: "center"}}
          onClose={() => setPasswordLimitOpen(false)}
          autoHideDuration={6000}
        >
          <Alert
            onClose={() => setPasswordLimitOpen(false)}
            severity="error"
            sx={{width: "100%"}}
          >
            Attempt limit exceeded, please try after some time.
          </Alert>
        </Snackbar>

        <Snackbar
          open={userInvalidOpen}
          anchorOrigin={{vertical: "bottom", horizontal: "center"}}
          onClose={() => setUserInvalidOpen(false)}
          autoHideDuration={10000}
        >
          <Alert
            onClose={() => setUserInvalidOpen(false)}
            severity="error"
            sx={{width: "100%"}}
          >
            Incorrect Old Password!
          </Alert>
        </Snackbar>

      </Box>
    </>
  );
}

export default ChangePassword;
