import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Alert, Grid, Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import './RegisterGeneral.scss';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

type Inputs = {
  cognito_user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
};

type Props = {
  data: any;
  setData: Function;
  setStep: Function;
  step: number;
};

function RegisterGeneral(props: Props) {
  useDocumentTitle("Register | TomFit AG - Switzerland");
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Inputs>();

  const [signUpFailedOpen, setSignUpFailedOpen] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    props.setData({
      ...props.data,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
    });
    props.setStep(props.step + 1);
  };

    return (
      <>
        <Typography variant="h1" component="h1">
          Start your transformation today!
        </Typography>
        <Box component="form" sx={{m: 1}}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={6}>
              <TextField
                error={errors.first_name ? true : false}
                id="Firstname"
                label="First name"
                variant="outlined"
                className="form-control"
                value={props.data.first_name}
                type="text"
                {...register("first_name", {
                  onChange: (e) => {props.setData({...props.data, first_name: e.target.value})},
                  required: true,
                  maxLength: 20,
                  minLength: 3,
                  pattern: /^[A-Za-züöäéèàÜÖÄÉÈÀ]+$/i,
                })}
              />
              {errors?.first_name?.type === "required" && (
                <span className="Alert">This field is required</span>
              )}
              {errors?.first_name?.type === "maxLength" && (
                <span className="Alert">
                First name cannot exceed 20 characters
              </span>
              )}
              {errors?.first_name?.type === "minLength" && (
                <span className="Alert">Cannot be less than 3 characters</span>
              )}
              {errors?.first_name?.type === "pattern" && (
                <span className="Alert">Alphabetical characters only</span>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={errors.last_name ? true : false}
                id="lastName"
                label="Last name"
                variant="outlined"
                className="form-control"
                type="text"
                value={props.data.last_name}
                {...register("last_name", {
                  onChange: (e) => {props.setData({...props.data, last_name: e.target.value})},
                  pattern: /^[A-Za-züöäéèàÜÖÄÉÈÀ]+$/i
                })}
              />
              {errors?.last_name?.type === "pattern" && (
                <span className="Alert">Alphabetical characters only</span>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.email ? true : false}
                id="Email"
                label="Email address"
                type="email"
                variant="outlined"
                value={props.data.email}
                className="form-control"
                {...register("email", {
                  onChange: (e) => {props.setData({...props.data, email: e.target.value})},
                  required: true, pattern: /\S+@\S+\.\S+/})
                }
              />
              {errors?.email?.type === "required" && (
                <span className="Alert"> This field is required</span>
              )}
              {errors?.email?.type === "pattern" && (
                <span className="Alert"> Invalid email</span>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.password ? true : false}
                id="Password"
                label="Password"
                type="password"
                variant="outlined"
                className="form-control"
                value={props.data.password}
                {...register("password", {
                  onChange: (e) => {props.setData({...props.data, password: e.target.value})},
                  required: true, minLength: 8, pattern: /(?=.*\d)(?=.*[a-z]).{8,}/})}
              />
              {errors?.password?.type === "required" && (
                <span className="Alert"> This field is required</span>
              )}
              {(errors?.password?.type === "pattern" || errors?.password?.type === "minLength") && (
                <span className="Alert">Must contain at least one number and one lowercase letter, and at least 8 characters</span>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box className="btn-loader">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  className="submit-btn w-100"
                  type="submit"
                >
                  Continue
                </Button>
                <Typography className="form-bt">
                  Already have an account? <Link to="/login">Login</Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Snackbar open={signUpFailedOpen} anchorOrigin={{vertical: "top", horizontal: "center"}}
                  autoHideDuration={6000}>
          <Alert
            onClose={() => setSignUpFailedOpen(false)}
            severity="error"
            sx={{width: "100%"}}
          >
            User Sign up failed!
          </Alert>
        </Snackbar>
      </>
    );
  };

  export default RegisterGeneral;
