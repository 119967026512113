import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './RegisterComplete.scss';
import animation from '../../../assets/images/animation.gif';

type Props = {
  data: any;
};

function RegisterComplete(props: Props) {
  return (
    <>
      <img className="image" src={animation} />
      <Typography variant="h1" component="h1" align="center" mb={4}>
        Congratulations, {props.data.first_name}!
      </Typography>
      <Box component="p">
        Your TomFit account has been created successfully!
      </Box>
      <Box className="greenTextBoxComplete">
        <Typography variant="h4" component="h4" align="center">
          Find your Tomly
        </Typography>
        <Box component="p">
          Go to your Tomly and use your mobile device to login and scan the code to get started.
        </Box>
      </Box>
      <Box className="or-box">
        or
      </Box>
      <Box className="whiteTextBox">
        <Typography variant="h4" component="h4" align="center">
          View your account
        </Typography>
        <Box component="p" mb={4}>
          Check out training plans and get to know how your progress will be tracked and updated.
        </Box>
        <Link to="/dashboard"><Button variant="outlined" className="grn-bdr-btn">GO TO DASHBOARD</Button></Link>
      </Box>
    </>
  );
}

export default RegisterComplete;
