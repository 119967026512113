import React, { createContext, useState } from "react";


// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const UserContext = createContext(undefined);
const UserDispatchContext = createContext<any>(undefined);


function UserContextProvider({ children } : any) {
  const [userDetails, setUserDetails] = useState();

  return (
    <UserContext.Provider value={userDetails}>
      <UserDispatchContext.Provider value={setUserDetails}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export { UserContextProvider, UserContext, UserDispatchContext };