import React, { useContext, useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import DashboardHeader from "../DashboardHead/DashboardHead";
import DashboardFoot from "../DashboardFoot/DashboardFoot";
import DashboardIcons from "../DashboardIcons/DashboardIcons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import backArrow from "../../assets/images/back-left-arrow.svg";
import moment from "moment";
import "./TrainingDetail.scss";
import customAxios from "../..";
import { ActivityContext, ActivityDispatchContext } from "../../context/ActivityContext";
import { Backdrop, CircularProgress } from "@mui/material";


function TrainingDetail() {
  const {state} : any = useLocation();
  const [openLoader, setOpenLoader] = useState(true);
  const userActivityDetails : any = useContext(ActivityContext);
  const setUserActivityDetails = useContext(ActivityDispatchContext);

  useEffect(() => {
    try {
      (async () => {
        await customAxios
          .get(`/users/api/session-overview?activity=${state.session}`)
          .then((result) => {
            const {data} = result;
            if(userActivityDetails)
            {
              userActivityDetails.overview = data;
              setUserActivityDetails(userActivityDetails);
              setOpenLoader(false);
            }
          });
      })();
    } catch (error: any) {
      setOpenLoader(false);
    }
  }, [userActivityDetails]);

  return (
    <>
      {openLoader ? (
        <Backdrop
          sx={{color: "#50C878", zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={openLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <DashboardHeader />
          <Box className="dashboard-main-area tranings-detail-area">
            <DashboardIcons />
            <Container fixed>
              <Box className="tranings-inner-area">
                <Box className="detail-top-area">
                  <Box className="detail-top-area-left">
                    <Box className="backarrow">
                      <Link to="/your-training">
                        <Box component="img" src={backArrow}></Box>
                      </Link>
                    </Box>
                    <Typography variant="h1">
                      {userActivityDetails.overview.name}
                    </Typography>
                    <Box className="traning-date">{moment(new Date(userActivityDetails.overview.header.date)).format("DD.MM.YYYY")} | {userActivityDetails.overview.header.type.toUpperCase()}</Box>
                  </Box>
                {/*  <Box className="detail-top-area-right">*/}
                {/*    <Box className="rank-tabs">*/}
                {/*      {userActivityDetails.overview.header.achievements?.personal_one && (*/}
                {/*        <Box className="badges-points">*/}
                {/*          <Box className="badges">*/}
                {/*            <Box className="badge gold-b">PB</Box>*/}
                {/*          </Box>*/}
                {/*          <Box className="points">*/}
                {/*            {*/}
                {/*              userActivityDetails.overview.header.achievements*/}
                {/*                ?.personal_one*/}
                {/*            }*/}
                {/*          </Box>*/}
                {/*        </Box>*/}
                {/*      )}*/}
                {/*      {userActivityDetails.overview.header.achievements?.personal_two && (*/}
                {/*        <Box className="badges-points">*/}
                {/*          <Box className="badges">*/}
                {/*            <Box className="badge silver-b">2</Box>*/}
                {/*          </Box>*/}
                {/*          <Box className="points">*/}
                {/*            {*/}
                {/*              userActivityDetails.overview.header.achievements*/}
                {/*                ?.personal_two*/}
                {/*            }*/}
                {/*          </Box>*/}
                {/*        </Box>*/}
                {/*      )}*/}
                {/*      {userActivityDetails.overview.header.achievements*/}
                {/*        ?.personal_three && (*/}
                {/*        <Box className="badges-points">*/}
                {/*          <Box className="badges">*/}
                {/*            <Box className="badge bronz-b">3</Box>*/}
                {/*          </Box>*/}
                {/*          <Box className="points">*/}
                {/*            {*/}
                {/*              userActivityDetails.overview.header.achievements*/}
                {/*                ?.personal_three*/}
                {/*            }*/}
                {/*          </Box>*/}
                {/*        </Box>*/}
                {/*      )}*/}
                {/*      {userActivityDetails.overview.header.achievements*/}
                {/*        ?.best_training && (*/}
                {/*        <div className="ach">*/}
                {/*          <Box component="img" src={starImg}></Box>*/}
                {/*        </div>*/}
                {/*      )}*/}
                {/*    </Box>*/}
                {/*  </Box>*/}
                </Box>
                <Box className="detail-info-tabs">
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <Box className="detail-info-tab">
                        <Typography component="h6">TOTAL REPS</Typography>
                        <Box className="detail-result">
                          {userActivityDetails.overview.header["total-reps"]}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box className="detail-info-tab">
                        <Typography component="h6">TOTAL TIME</Typography>
                        <Box className="detail-result">
                          {userActivityDetails.overview.header["total-time"]}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box className="detail-info-tab">
                        <Typography component="h6">KG LIFTED</Typography>
                        <Box className="detail-info-tab-inner">
                          <Box className="detail-result">
                            {userActivityDetails.overview.header["total-kg"]} KG
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Typography component="h6" className="bdr-heading">
                  EXERCISE DETAILS
                </Typography>
                <Box className="traning-detail-table">
                  <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="bb-0 ptb-12">EXERCISE</TableCell>
                          <TableCell className="bb-0 ptb-12">REPS</TableCell>
                          <TableCell className="bb-0 ptb-12" align="center">
                            TIME UNDER TENSION (SEC)
                          </TableCell>
                          <TableCell className="bb-0 ptb-12" align="center">
                            MAX STRENGTH (KG)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userActivityDetails.overview.details.map(
                          (exercise: any, index: any) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {exercise["name"]}
                              </TableCell>
                              <TableCell align="center">
                              <div className="table-cell">
                                {exercise["reps"]}
                                {/*{exercise?.achievements &&*/}
                                {/*  exercise?.achievements["effort_absolute"] ===*/}
                                {/*    "personal_one" && (*/}
                                {/*    <Box className="badge gold-b">PB</Box>*/}
                                {/*  )}*/}
                                {/*{exercise?.achievements &&*/}
                                {/*  exercise?.achievements["effort_absolute"] ===*/}
                                {/*    "personal_two" && (*/}
                                {/*    <Box className="badge silver-b">2</Box>*/}
                                {/*  )}*/}
                                {/*{exercise?.achievements &&*/}
                                {/*  exercise?.achievements["effort_absolute"] ===*/}
                                {/*    "personal_three" && (*/}
                                {/*    <Box className="badge bronz-b">3</Box>*/}
                                {/*)}*/}
                              </div>
                              </TableCell>
                              <TableCell align="center">
                                <div className="table-cell">
                                  {exercise["tut"]}
                                  {/*{exercise?.achievements &&*/}
                                  {/*  exercise?.achievements["effort_escore"] === "personal_one" && (*/}
                                  {/*    <Box className="badge gold-b">PB</Box>*/}
                                  {/*  )}*/}
                                  {/*{exercise?.achievements &&*/}
                                  {/*  exercise?.achievements["effort_escore"] === "personal_two" && (*/}
                                  {/*    <Box className="badge silver-b">2</Box>*/}
                                  {/*  )}*/}
                                  {/*{exercise?.achievements &&*/}
                                  {/*  exercise?.achievements["effort_escore"] ===*/}
                                  {/*    "personal_three" && (*/}
                                  {/*    <Box className="badge bronz-b">3</Box>*/}
                                  {/*)}*/}
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <div className="table-cell">
                                  {exercise["max_strength"]}
                                  {/*{exercise?.achievements &&*/}
                                  {/*  exercise?.achievements["quality_absolute"] ===*/}
                                  {/*    "personal_one" && (*/}
                                  {/*    <Box className="badge gold-b">PB</Box>*/}
                                  {/*  )}*/}
                                  {/*{exercise?.achievements &&*/}
                                  {/*  exercise?.achievements["quality_absolute"] ===*/}
                                  {/*    "personal_two" && (*/}
                                  {/*    <Box className="badge silver-b">2</Box>*/}
                                  {/*  )}*/}
                                  {/*{exercise?.achievements &&*/}
                                  {/*  exercise?.achievements["quality_absolute"] ===*/}
                                  {/*    "personal_three" && (*/}
                                  {/*    <Box className="badge bronz-b">3</Box>*/}
                                  {/*)}*/}
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Container>
          </Box>
          <DashboardFoot />
        </>
      )}
    </>
  );
}

export default TrainingDetail;
