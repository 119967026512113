import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

/*Import Images */
import dlogo from "../../assets/images/dashboard-logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
/*Step Icon*/
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import "./DashboardHead.scss";
import { Auth } from "aws-amplify";
import {Backdrop, CircularProgress } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import calendar from "../../assets/images/calendar-schedule.svg";
import { ReactComponent as Supervised } from "../../assets/images/supervised.svg";
import { ReactComponent as NotSupervised } from "../../assets/images/not_supervised.svg";
import {PopupModal} from "react-calendly";

function DashboardHead() {
  const location = useLocation();
  const navigate = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const showMenu = () => {
    setActiveMenu(!activeMenu);
  };
  const userDetails : any = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElementSchedule, setAnchorElementSchedule] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [openScheduleNotSupervisedModal, setOpenScheduleNotSupervisedModal] = useState(false);
  const [openScheduleSupervisedModal, setOpenScheduleSupervisedModal] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickSchedule = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementSchedule(event.currentTarget);
    setOpenSchedule(!openSchedule);
  };
  const handleCloseSchedule = () => {
    setOpenSchedule(false);
  };

  const signOut = async () => {
    setOpenLoader(true);
    if (localStorage.getItem("idToken")) {
      const response = await Auth.signOut().then(() =>{
        localStorage.clear();
        setOpenLoader(false);
        navigate("/login");
      });
    }
  };

  const ScheduleMenu = () => {
    return <Menu
      className="schedule-nav-menu"
      anchorEl={anchorElementSchedule}
      id="schedule"
      open={openSchedule}
      onClose={handleCloseSchedule}
      onClick={handleCloseSchedule}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiPaper-root": {
            borderRadius: "24px"
          },
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{horizontal: "right", vertical: "top"}}
      anchorOrigin={{horizontal: "right", vertical: "bottom"}}
    >
      <MenuItem>
        <a target="_blank" onClick={() => setOpenScheduleSupervisedModal(true)}>
          <Box sx={{ display: 'flex' }}>
            <Box className="schedule-menu-icon-supervised">
              <Supervised />
            </Box>
            <Box>
              <Box className="schedule-menu-title">Supervised</Box>
              <Box className="schedule-menu-text">Recommended for initial training sessions.</Box>
            </Box>
          </Box>
        </a>
      </MenuItem>
      <MenuItem>
        <a target="_blank" onClick={() => setOpenScheduleNotSupervisedModal(true)}>
          <Box sx={{ display: 'flex' }}>
            <Box className="schedule-menu-icon-not-supervised">
              <NotSupervised />
            </Box>
            <Box>
              <Box className="schedule-menu-title">Not supervised</Box>
              <Box className="schedule-menu-text">Recommended for regular training sessions.</Box>
            </Box>
          </Box>
        </a>
      </MenuItem>
    </Menu>;
  }

  return (
    <>
      <Box className="dashboard-head">
        <Box className="dashboard-logo">
          <Link to="/">
            <Box component="img" src={dlogo} className="dashboard-logo-img"></Box>
          </Link>
        </Box>
        <MenuItem className="mobile-item">
          <Box className="schedule-button" onClick={handleClickSchedule}>
            <Tooltip title="SCHEDULE TRAINING">
              <Box component="img" className="calendar-icon" src={calendar}></Box>
            </Tooltip>
            SCHEDULE
            <ScheduleMenu />
          </Box>
        </MenuItem>
        <Box className="mobile-menu-bar" onClick={showMenu}>
          <MenuIcon />
        </Box>
        <div className={activeMenu ? "dashboard-head-right active" : "dashboard-head-right"}>
          <Box className="dashboard-nav">
            <nav>
              <List>
                <ListItem selected={location.pathname == "/dashboard" ? true : false}>
                  <Link to="/dashboard">
                    <ListItemButton>
                      <ListItemText primary="Dashboard" />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem selected={location.pathname == "/your-training" ? true : false}>
                  <Link to="/your-training">
                    <ListItemButton>
                      <ListItemText primary="Your Training" />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem selected={location.pathname == "/tomly" ? true : false}>
                  <Link to="/tomly">
                    <ListItemButton>
                      <ListItemText primary="Tomly’s" />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem className="mobile-item" selected={location.pathname == "/profile" ? true : false}>
                  <Link to="/profile">
                    <ListItemButton>
                      <ListItemText primary="Profile" />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </List>
            </nav>
          </Box>
          <MenuItem className="mobile-item mobile-logout-btn" onClick={signOut}>
            <Link className="mobile-item mobile-logout-btn" to="#">
              Logout
            </Link>
          </MenuItem>
          <MenuItem>
            <Box className="schedule-button" onClick={handleClickSchedule}>
              <Tooltip title="SCHEDULE TRAINING">
                <Box component="img" className="calendar-icon" src={calendar}></Box>
              </Tooltip>
              SCHEDULE TRAINING
              <span className="arrow-icon">
                <KeyboardArrowDownIcon />
              </span>
              <ScheduleMenu />
            </Box>
          </MenuItem>
          <Box className="profile-nav" onClick={handleClick}>
            <Tooltip title="Account settings">
              <IconButton
                size="small"
                sx={{ml: 2}}
                aria-controls={anchorEl ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? "true" : undefined}
              >
                <Avatar>{userDetails.first_name[0].toUpperCase()}</Avatar>
              </IconButton>
            </Tooltip>
            {userDetails.first_name + " " + userDetails.last_name}
            <span>
              <KeyboardArrowDownIcon />
            </span>
            <Menu
              className="profile-nav-menu"
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: "right", vertical: "top"}}
              anchorOrigin={{horizontal: "right", vertical: "bottom"}}
            >
              <MenuItem>
                <Link to="/profile">
                  Profile
                  </Link>
              </MenuItem>
              <MenuItem>
                <Link to="" onClick={signOut}>
                  Logout
                  </Link>
              </MenuItem>
            </Menu>
          </Box>
        </div>
      </Box>

      <PopupModal
        url={`https://calendly.com/tomfit2023/30min/30min?month=2023-08&hide_gdpr_banner=1&name=${userDetails.first_name}%20${userDetails.last_name}&email=${userDetails.email}`}
        onModalClose={() => {setOpenScheduleSupervisedModal(false)}}
        open={openScheduleSupervisedModal}
        rootElement={document.getElementById("root")!}
      />
      <PopupModal
        url={`https://calendly.com/tomfit2023/tomfit-ag-trial/30min?month=2023-08&hide_gdpr_banner=1&name=${userDetails.first_name}%20${userDetails.last_name}&email=${userDetails.email}`}
        onModalClose={() => {setOpenScheduleNotSupervisedModal(false)}}
        open={openScheduleNotSupervisedModal}
        rootElement={document.getElementById("root")!}
      />

      <Backdrop
        sx={{ color: '#50C878', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default DashboardHead;
