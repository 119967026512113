import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

import "./YourStatsList.scss";
import { ActivityContext } from "../../context/ActivityContext";
import moment from "moment";

function YourStatsList(props: any) {
  const userActivityDetails : any = useContext(ActivityContext);
  const [muscleMonthsData, setMuscleMonthsData] = useState([]);
  const [muscleMassData, setMuscleMassData] = useState([]);
  const [weightMonthsData, setWeightMonthsData] = useState([]);
  const [weightData, setWeightData] = useState([]);

  ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


  useEffect(() => {
    let localMuscleMonthsData : any = [];
    let localMuscleMassData : any = [];
    

    for(let i = userActivityDetails?.stats?.musclemass?.length - 1; i >=0; i--){
      localMuscleMonthsData.push(
        moment(userActivityDetails.stats.musclemass[i].month, "MM").format("MMM").toUpperCase()
      );
    }

    for(let i = userActivityDetails?.stats?.musclemass?.length - 1; i >=0; i--){
      localMuscleMassData.push(userActivityDetails.stats.musclemass[i].muscle_mass_average);
    }

    setMuscleMonthsData(localMuscleMonthsData);    
    setMuscleMassData(localMuscleMassData);

    let localWeightMonthsData : any = [];
    let localWeightData : any = [];
    

    for(let i = userActivityDetails?.stats?.weight?.length - 1; i >=0; i--){
      localWeightMonthsData.push(
        moment(userActivityDetails.stats.weight[i].month, "MM").format("MMM").toUpperCase()
      );
    }

    for(let i = userActivityDetails?.stats?.weight?.length - 1; i >=0; i--){
      localWeightData.push(userActivityDetails.stats.weight[i].weight_average);
    }

    setWeightMonthsData(localWeightMonthsData);    
    setWeightData(localWeightData);
  }, [])

  return (
    <>
      <Box className="status-box">
        <Typography component="h2">Your Stats</Typography>
        {userActivityDetails?.stats?.musclemass?.length == 0 ? (
          <Box className="status-box-tab status-margin">
            <Typography component="h5">MUSCLE MASS</Typography>
            <Box className="status-box-empty-note">
              <p>Enter to start tracking your progress</p>
              <Button
                variant="outlined"
                className="update grn-bdr-btn"
                onClick={() => props.setIsStatsEditable(true)}
              >
                Add
              </Button>
            </Box>
          </Box>
        ) : (
          <Box className="status-box-tab">
            <Typography component="h5">MUSCLE MASS</Typography>
            <Box className="status-box-graph">
            <Line
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      ticks: {
                        color: "#6C7F81",
                        font: {
                          size: 8,
                          weight: "bold",
                          family: "Outfit",
                        },
                      },
                    },
                    x: {
                      ticks: {
                        color: "#6C7F81",
                        font: {
                          size: 8,
                          weight: "bold",
                          family: "Outfit",
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: muscleMonthsData,
                  datasets: [
                    {
                      data: muscleMassData,
                      borderColor: "rgb(80, 200, 120)",
                      backgroundColor: "rgba(80, 200, 120, 1)",
                    },
                  ],
                }}
              />
              {/* <Box component="img" src={mmGraph}></Box> */}
            </Box>
            <Box className="status-box-tab-status">
              <span>{userActivityDetails.stats["musclemass-current"]}%</span>
              {userActivityDetails.stats["musclemass-delta"] >= 0 ? (
                <span className="per">
                  +{userActivityDetails.stats["musclemass-delta"]}%
                </span>
              ) : (
                <span className="per perMinus">
                  {userActivityDetails.stats["musclemass-delta"]}%
                </span>
              )}
            </Box>
          </Box>
        )}
        <Box className="status-box-tab">
          <Typography component="h5">WEIGHT</Typography>
          <Box className="status-box-graph">
          <Line
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      ticks: {
                        color: "#6C7F81",
                        font: {
                          size: 8,
                          weight: "bold",
                          family: "Outfit",
                        },
                      },
                    },
                    x: {
                      ticks: {
                        color: "#6C7F81",
                        font: {
                          size: 8,
                          weight: "bold",
                          family: "Outfit",
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: weightMonthsData,
                  datasets: [
                    {
                      data: weightData,
                      borderColor: "rgb(106, 124, 126)",
                      backgroundColor: "rgba(106, 124, 126, 1)",
                    },
                  ],
                }}
              />
            {/* <Box component="img" src={wGraph}></Box> */}
          </Box>
          <Box className="status-box-tab-status">
            <span>{userActivityDetails.stats["weight-current"]}kg</span>
          </Box>
        </Box>
        <Button
          variant="outlined"
          className="update grn-bdr-btn"
          onClick={() => props.setIsStatsEditable(true)}
        >
          UPDATE
        </Button>
      </Box>
    </>
  );
}

export default YourStatsList;