import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, Snackbar } from '@mui/material';
import backIcon from '../../assets/images/back.svg';
import logo from '../../assets/images/tomfit.png';
import mobilelogo from '../../assets/images/logo-mobile.png';
import hangingmen from '../../assets/images/hanging-men.svg';
import img1 from '../../assets/images/Vector-6.png';
import img2 from '../../assets/images/blue-circle.png';
import img3 from '../../assets/images/cl-img.png';
import ibw from '../../assets/images/ibw-img.png';
import lcm from '../../assets/images/lcm-img.png';
import gcm from '../../assets/images/gc-img.png';
import cry from '../../assets/images/cry-img.png';
import bmbr from '../../assets/images/bmbr-img.png';
import trl from '../../assets/images/trl-img.png';
import hybl from '../../assets/images/hybl-img.png';
import './Register.scss';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import RegisterGeneral from './RegisterGeneral/RegisterGeneral';
import RegisterHeight from './RegisterHeight/RegisterHeight';
import RegisterWeight from './RegisterWeight/RegisterWeight';
import RegisterBirthday from './RegisterBirthday/RegisterBirthday';
import RegisterComplete from './RegisterComplete/RegisterComplete';
import LinearProgress from '@mui/material/LinearProgress';
import Carousel from "react-material-ui-carousel";


function Register() {
  useDocumentTitle("Register | TomFit AG - Switzerland");
  const [signUpFailedOpen, setSignUpFailedOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});

  const components = [
    RegisterGeneral,
    RegisterHeight,
    RegisterWeight,
    RegisterBirthday,
    RegisterComplete
  ]

  return (
    <>
      <Box className="main-area">
        <Box component="img" src={img1} className="sbr-img"></Box>
        <Box component="img" src={cry} className="cry-img"></Box>
        <Box className="mobile-icons">
          <Box component="img" src={mobilelogo} className="mobile-logo"></Box>
          <Box component="img" src={bmbr} className="bmbr-img"></Box>
          <Box component="img" src={trl} className="trl-img"></Box>
          <Box component="img" src={hybl} className="hybl-img"></Box>
        </Box>

        <Box className="main-inner-area">
          <Box component="img" src={img3} className="cl-img"></Box>
          <Box component="img" src={img2} className="bc-img"></Box>
          <Box className="main-inner-left">
            <Box component="img" className="logo" alt="" src={logo} />
            <Box component="img" src={hangingmen} className="hangingmen"></Box>
            <Box component="img" src={gcm} className="gc-img"></Box>
            <Box component="img" src={ibw} className="imw-img"></Box>
            <Box component="img" src={ibw} className="imw-img"></Box>
            <Box component="img" src={lcm} className="lcm-img"></Box>
          </Box>
          <Box className="main-inner-right register-form">

            <Box className={`${step == 1 ? "form-area" : ""}`}>
              {step != 1 && step != 5 &&
                <Box className="backIcon" onClick={() => {
                  setStep(step - 1);
                }}>
                  <Box component="img" src={backIcon}></Box>
                </Box>
              }

              {step != 1 &&
                <Box className="form-progressbar">
                  <LinearProgress variant="determinate" value={step * 20} />
                </Box>
              }

              <Carousel
                IndicatorIcon={false}
                animation="slide"
                autoPlay={false}
                navButtonsAlwaysInvisible={true}
                swipe={false}
                index={step - 1}
                duration={700}
              >
                {
                  components.map( (item, i) => React.createElement(item, {
                    step: step, data: data, setStep: setStep, setData: setData
                  }) )
                }
              </Carousel>
            </Box>
          </Box>
        </Box>
      </Box>

      <Snackbar open={signUpFailedOpen} anchorOrigin={{vertical: "top", horizontal: "center"}} autoHideDuration={6000}>
        <Alert
          onClose={() => setSignUpFailedOpen(false)}
          severity="error"
          sx={{width: "100%"}}
        >
          User Sign up failed!
        </Alert>
      </Snackbar>
    </>
  );
}

export default Register;
