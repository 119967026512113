import React, { useContext, useEffect } from "react";
import {Navigate, useNavigate} from "react-router-dom";
import customAxios from ".";
import { ActivityContext, ActivityDispatchContext } from "./context/ActivityContext";
import { UserContext, UserDispatchContext } from "./context/UserContext";

const PrivateRoute = ({children}: any) => {
  const navigate = useNavigate();
  const userDetails : any = useContext(UserContext);
  const setUserDetails = useContext(UserDispatchContext);
  const userActivityDetails : any = useContext(ActivityContext);
  const setUserActivityDetails = useContext(ActivityDispatchContext);

  useEffect(() => {
    let idToken = localStorage.getItem("idToken");
    let username = localStorage.getItem("username");

    (async () => {

    if (!userDetails && idToken) {
        await customAxios
          .get(`/users/api/user/${username}`)
          .then((result) => {
            const {data} = result;
            setUserDetails(data);
          })
          .catch((error) => {
              localStorage.clear();
              navigate("/login");
          });
    }

    if (!userActivityDetails && idToken) {
      let userActivityDetails: any = {};
      try{
          await customAxios
            .get(`/users/api/activity?cognito_user_id=${username}`)
            .then((result) => {
              const {data} = result;
              userActivityDetails.progress = data;
            });
          await customAxios
            .get(`/users/api/user/stats/${username}`)
            .then((result) => {
              const {data} = result;
              userActivityDetails.stats = data;
            });
          await customAxios
            .get(`/users/api/workout?cognito_user_id=${username}`)
            .then((result) => {
              const {data} = result;
              userActivityDetails.training = data;

              setUserActivityDetails(userActivityDetails);
            });
      }
      catch (error: any) {
        console.log(error);
      }
    }
  })();

  }, []);
  
  const isAuth = localStorage.getItem("idToken") ? true : false;
  return isAuth ? userDetails && children : <Navigate to="/login" />;
};

export default PrivateRoute;
