import React, {Fragment, useContext, useEffect, useState} from "react";
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import Register from "./components/Register/Register";
import Confirmation from "./components/Confirmation/Confirmation";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/Forgot-Password/ForgotPassword";
import Dashboard from "./components/Dashboard/Dashboard";
import ConfirmPassword from "./components/Confirm-Password/ConfirmPassword";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import YourTraning from "./components/YourTraining/YourTraining";
import Profile from "./components/Profile/Profile";
import TrainingDetail from "./components/TrainingDetail/TrainingDetail";
import Tomly from "./components/Tomly/Tomly";
import Qrscanner from "./components/Qrscanner/Qrscanner";

function ScrollToTop() {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function CustomRouter() {

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
           <Route
            path="/:fcm_token/:cognito_machine_id"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/confirmPassword" element={<ConfirmPassword />} />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/dummydashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/your-training"
            element={
              <PrivateRoute>
                <YourTraning />
              </PrivateRoute>
            }
          />
          <Route
            path="/training-detail"
            element={
              <PrivateRoute>
                <TrainingDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/tomly"
            element={
              <PrivateRoute>
                <Tomly />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/qrscanner"
            element={
              <PrivateRoute>
                <Qrscanner />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default CustomRouter;
