import React, {useEffect} from "react";
import QrScanner from "qr-scanner";
import customAxios from "../..";
import { Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";

// import Images
import frameImage from '../../assets/images/frame-icon.svg';

import "./Qrscanner.scss";

const Qrscanner = () => {
  const navigate = useNavigate();

    async function setResult(result: any) {
      debugger;
        var parts = result.split("/");
        var fcm_token = atob(parts[3]);
        var cognito_machine_id = atob(parts[4]);
        var params = "cognito_machine_id=" + cognito_machine_id + "&fcm_token=" + fcm_token;
        await customAxios.get(`users/api/user-start/?${params}`).then((response) => {
        // document.getElementById("scannerData")!.textContent = JSON.stringify(response.data.results);
        console.log(JSON.stringify(response.data.results));
        navigate("/dashboard");
        })
        .catch((error) => {
          console.log(error);
        })
    }

    useEffect(() => {
        const videoElem = document.getElementById("videoElem")! as HTMLVideoElement;
        const qrScanner = new QrScanner(videoElem, (result: any) => {
          qrScanner.stop();
          setResult(result.data)}, {
          returnDetailedScanResult: true,
          highlightCodeOutline: true,
        });
        QrScanner.hasCamera()
          .then(() => {
            qrScanner.start();
          })
          .catch((e) => {
            console.log("error");
          });
    }, []);
    
    return (
        <>
         <div className="qr-scanner-area">
        <div className="qr-scanner-camera">
          <video id="videoElem"></video>
          <div id="scannerData"></div>
          <div className="qr-scanner-frame"><Box component="img" src={frameImage}></Box></div>
        </div>
        <div className="dashboard-foot">
          <div className="mobile-scanner">
            <Button variant="contained" className="grn-btn">
              SCAN CODE
            </Button>
          </div>
        </div>
      </div>
        </>
    );
}

export default Qrscanner;