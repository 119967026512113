import React, { useContext, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/*Import Images */
import starImg from "../../assets/images/star.svg";
import "./CompletedTraningTab.scss";
import { ActivityContext } from "../../context/ActivityContext";
import moment from "moment";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";

const trainingsPerColumn = 3;

function CompletedTraningTab() {
  let location = useLocation();
  // let showRecords = location.pathname === "/dashboard" ? 3 : userActivityDetails?.training.completed.length >= 6 ? 6 : userActivityDetails?.training.completed.length;
  const userActivityDetails : any = useContext(ActivityContext);
  const [next, setNext] = useState(trainingsPerColumn);

  const handLoadMore = () => {
    if (next + trainingsPerColumn >= userActivityDetails.training.completed.length) {
      setNext(userActivityDetails.training.completed.length);
    } else {
      setNext(next + trainingsPerColumn);
    }
  };
  
  
  return (
    <>
      {userActivityDetails?.training.completed.length !== 0 ?
      userActivityDetails?.training.completed.slice(0, next).map((item: any, index: any) => (
        <Link key={index} to="/training-detail" state={{session: item.activity}} className="completed-session-tab">
          <Box className="completed-session-tab-date">
          {moment(new Date(item.date)).format("D")}
            <span>
            {moment(new Date(item.date)).format("MMM")}
            </span>
          </Box>
          <Box className="completed-session-tab-info">
            <Typography component="h5">
              {item.name} <span>{item.type.toUpperCase()}</span>
            </Typography>
          </Box>
          {item.achievements &&
          <Box className="rank-tabs">
            {/*{item.achievements.medals.best_training &&*/}
            {/*<div className="ach">*/}
            {/*  <Box component="img" src={starImg}></Box>*/}
            {/*</div>*/}
            {/*}*/}
            {/*{item.achievements.medals &&*/}
            {/*<Box className="badges-points">*/}
            {/*  <Box className="badges">*/}
            {/*    /!* {item.achievements.medals.personal_one && !item.achievements.medals.personal_two  && !item.achievements.medals.personal_three ?*/}
            {/*    <Box className="badge gold-b">{item.achievements.medals.personal_one}</Box>*/}
            {/*    :*/}
            {/*    <Box className="badge gold-b">PB</Box>*/}
            {/*  } *!/*/}
            {/*  {item.achievements.medals.personal_one &&*/}
            {/*    <Box className="badge gold-b">PB</Box>*/}
            {/*  }*/}
            {/*  {item.achievements.medals.personal_two &&*/}
            {/*    <Box className="badge silver-b">{item.achievements.medals.personal_two}</Box>*/}
            {/*  }*/}
            {/*  {item.achievements.medals.personal_three &&*/}
            {/*    <Box className="badge bronz-b">{item.achievements.medals.personal_three}</Box>*/}
            {/*  }*/}
            {/*  </Box>*/}
            {/*  <Box className="points">{item.achievements.total}</Box>*/}
            {/*</Box>*/}
            {/*}*/}
          </Box>
          }
        </Link>
      ))
        :
        <NoDataAvailable/>}
      {next < userActivityDetails.training.completed.length && location.pathname !== "/dashboard" && (
        <Box className="loadmore" onClick={() => handLoadMore()}>
          Load More
        </Box>
      )}
    </>
  );
}

export default CompletedTraningTab;
