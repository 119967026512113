import React, { useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {Alert, Grid, Snackbar} from "@mui/material";
import Button from "@mui/material/Button";

/*Import Images */
import logo from "../../assets/images/tomfit.png";
import mobilelogo from "../../assets/images/logo-mobile.png";
import hangingmen from "../../assets/images/hanging-men.svg";
import img1 from "../../assets/images/Vector-6.png";
import img2 from "../../assets/images/blue-circle.png";
import img3 from "../../assets/images/cl-img.png";
import ibw from "../../assets/images/ibw-img.png";
import lcm from "../../assets/images/lcm-img.png";
import gcm from "../../assets/images/gc-img.png";
import cry from "../../assets/images/cry-img.png";
import bmbr from "../../assets/images/bmbr-img.png";
import trl from "../../assets/images/trl-img.png";
import hybl from "../../assets/images/hybl-img.png";

import {SubmitHandler, useForm, useFormState} from "react-hook-form";
import customAxios from "../..";
import {Auth} from "aws-amplify";
import useDocumentTitle from "../../hooks/useDocumentTitle";

type Inputs = {
  email: string;
};

function ForgotPassword() {
  useDocumentTitle("Forgot Password | TomFit AG - Switzerland");
  const navigate = useNavigate();
  const { state } : any = useLocation();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (values) => {
    Auth.forgotPassword(values.email).then((value)=>{
       navigate("/confirmPassword", {state: {email: values.email}});
      })
      .catch((error: any)=>{
      console.log(error)})
    }

  return (
    <>
      <Box className="main-area">
        <Box component="img" src={img1} className="sbr-img"></Box>
        <Box component="img" src={cry} className="cry-img"></Box>
        <Box className="mobile-icons">
          <Box component="img" src={mobilelogo} className="mobile-logo"></Box>
          <Box component="img" src={bmbr} className="bmbr-img"></Box>
          <Box component="img" src={trl} className="trl-img"></Box>
          <Box component="img" src={hybl} className="hybl-img"></Box>
        </Box>
        <Box className="main-inner-area">
          <Box component="img" src={img3} className="cl-img"></Box>
          <Box component="img" src={img2} className="bc-img"></Box>
          <Box className="main-inner-left">
            <Box component="img" className="logo" alt="" src={logo} />
            <Box component="img" src={hangingmen} className="hangingmen"></Box>
            <Box component="img" src={gcm} className="gc-img"></Box>
            <Box component="img" src={ibw} className="imw-img"></Box>
            <Box component="img" src={ibw} className="imw-img"></Box>
            <Box component="img" src={lcm} className="lcm-img"></Box>
          </Box>
          <Box className="main-inner-right main-inner-right-password">
            <Box className="form-area">
              <Typography variant="h1" component="h1">
                <Typography component="span">FORGOT PASSWORD</Typography>Enter the email associated with your account!
              </Typography>
              <Box component="form" sx={{m: 1}}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={errors.email ? true : false}
                      id="Email"
                      label="Email address"
                      defaultValue={state?.email ? state.email : ""}
                      type="email"
                      variant="outlined"
                      className="form-control"
                      {...register("email", {required: true, pattern: /\S+@\S+\.\S+/})}
                    />
                    {errors?.email?.type === "required" && (
                      <span className="Alert"> This field is required</span>
                    )}
                    {errors?.email?.type === "pattern" && (
                      <span className="Alert"> Invalid email</span>
                    )}
                  </Grid>
                 
                  <Grid item xs={12}>
                    <Link to="/confirmation">
                      <Button
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        className="submit-btn"
                      >
                        Submit
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
              <Typography className="form-bt">
                Already have an account? <Link to="/login">Login</Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ForgotPassword;
