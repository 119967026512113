import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import stepIcon from '../../../assets/images/calendar.svg';
import './RegisterBirthday.scss';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserDispatchContext } from '../../../context/UserContext';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Auth } from 'aws-amplify';
import customAxios from '../../../index';
import { CircularProgress } from '@mui/material';

type Inputs = {
  day: number;
  month: number;
  year: number;
  cognito_user_id: string;
};

type Props = {
  data: any;
  setData: Function;
  setStep: Function;
  step: number;
};

function RegisterBirthday(props: Props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const setUserDetails = useContext(UserDispatchContext);
  const submitButtonRef= useRef<HTMLInputElement>(null);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    try {
      setOpen(true);

      const {user} = await Auth.signUp({
        username: props.data.email,
        password: props.data.password,
      });

      //Auto Sign in after succesfull sign up
      const response = await Auth.signIn({
        username: props.data.email,
        password: props.data.password,
      });

      //Storing user tokens for user session and api calls
      localStorage.setItem("username", response.username);
      localStorage.setItem('idToken', response.signInUserSession.idToken.jwtToken);
      localStorage.setItem('accessToken', response.signInUserSession.accessToken.jwtToken);
      localStorage.setItem('refreshToken', response.signInUserSession.refreshToken.token);

      //Signing up user account in local db for the successfully created cognito user
      props.setData({...props.data,
        birthday: new Date(values.year, values.month - 1, values.day).toISOString().split('T')[0],
        cognito_user_id: response.username
      });
    } catch (error: any) {
      setOpen(false);
      if (error.code === "UsernameExistsException") {
        navigate("/login", {state: {userAlreadyExists: true, email: props.data.email}});
      }
    }
  };

  useEffect(() => {
    // only if it has cognito_user_id in response
    if (props.data.birthday && props.data.cognito_user_id) {
      customAxios.post(`users/api/user`, props.data).then((result) => {
        const {data} = result;
        setUserDetails(props.data);
        setOpen(false);
        props.setStep(props.step + 1);
      }).catch((error) => {
        console.log(error)
        setError(true);
      })
    }
  }, [props.data]);

  return (
    <>
      <Typography variant="h1" component="h1">
        What is your date <br></br>of birth?
      </Typography>
      <Box component="form">
        <Grid container>
          <Grid item>
            <Box className="cong-tab-icon stepIcon">
              <Box component="img" src={stepIcon}></Box>
            </Box>
            <Box className="greenTextBox">
              By providing your age, we can help ensure that you get the most out of Tomly
              while minimizing the risk of injury.
            </Box>
            <Box className="step-form">
              <div className="form-input-wrapper">
              <Box className="icon-field register-birthday-input-label-box">
                <Box className="birthday-input-label">Day</Box>
                <Box className="birthday-input-label">Month</Box>
                <Box className="birthday-input-label">Year</Box>
              </Box>
              <Box className="icon-field birthday-input-box">
                <TextField
                  error={errors.day ? true : false}
                  type="number"
                  id="day"
                  label="DD"
                  inputProps={{min: 1, max: 31}}
                  variant="outlined"
                  className="form-control"
                  {...register("day", {
                    required: true,
                    minLength: 1,
                    maxLength: 2,
                    min: 1,
                    max: 31,
                    pattern: /([1-9]|[12]\d|3[01])$/,
                  })}
                />
                <TextField
                  error={errors.month ? true : false}
                  type="number"
                  id="month"
                  label="MM"
                  variant="outlined"
                  className="form-control"
                  {...register("month", {
                    required: true,
                    minLength: 1,
                    maxLength: 2,
                    min: 1,
                    max: 12,
                    pattern: /^(0?[1-9]|1[012])$/,
                  })}
                />
                <TextField
                  error={errors.year ? true : false}
                  type="number"
                  id="year"
                  label="YYYY"
                  variant="outlined"
                  className="form-control birthday-year"
                  {...register("year", {
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                    min: 1900,
                    max: new Date().getFullYear(),
                    pattern: /^(19|20)\d{2}$/,
                  })}
                />
              </Box>
              </div>
              {errors?.day?.type === "required" && errors?.month?.type === "required" && errors?.year?.type === "required" && (
                <div className="Alert"> This field is required</div>
              )}
              {((errors?.day?.type === "max" || errors?.month?.type === "max" || errors?.year?.type === "max") ||
                  (errors?.day?.type === "min" || errors?.month?.type === "min" || errors?.year?.type === "min")) &&
                <div className="Alert">Please enter a valid date of birth</div>
              }
              {error &&
                <div className="Alert">Something went wrong</div>
              }
              {open ? <div className="loading"><CircularProgress /></div> :
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  className="grn-btn w-100"
                  type="submit"
                >
                  CONTINUE
                </Button>
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default RegisterBirthday;
