import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import stepIcon from '../../../assets/images/weight-icon.svg';
import './RegisterWeight.scss';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserContext, UserDispatchContext } from '../../../context/UserContext';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

type Inputs = {
  weight: number;
};

type Props = {
  data: any;
  setData: Function;
  setStep: Function;
  step: number;
};

function RegisterWeight(props: Props) {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    props.setData({...props.data,
      weight: values.weight,
    });
    props.setStep(props.step + 1);
  };

  return (
    <>
      <Typography variant="h1" component="h1">
        Now add your <br></br>weight...
      </Typography>
      <Box component="form">
        <Grid container>
          <Grid item>
            <Box className="cong-tab-icon stepIcon">
              <Box component="img" src={stepIcon}></Box>
            </Box>
            <Box className="greenTextBox">
              This allows the machine to adjust ergonomically for each exercise for a more
              comfortable training experience
            </Box>
            <Box className="step-form">
              <form>
                <Box>
                  <div className="form-input-wrapper">
                  <TextField
                    error={errors.weight ? true : false}
                    type="number"
                    id="height"
                    label="Enter weight"
                    variant="outlined"
                    value={props.data.weight}
                    className="form-control"
                    {...register("weight", {
                      onChange: (e) => {props.setData({...props.data, weight: e.target.value})},
                      required: true
                    })}
                  />
                  <Box className="field-icon">kg</Box>
                  </div>
                </Box>
                {errors?.weight?.type === "required" && (
                  <span className="Alert"> This field is required</span>
                )}
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  className="grn-btn"
                  type="submit"
                >
                  CONTINUE
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default RegisterWeight;
